/* typography */
#inventory {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  font-weight: normal;
  letter-spacing: 0.1rem;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

#inventory-container h3 {
  padding: 1rem;
  font-size: 1rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 0.3rem;
  color: yellow !important;
}

#inventory-container h4 {
  font-size: 1rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 0.3rem;
}

#inventory ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

#inventory ul li,
#mercenaries-list ul li {
  border-left: 0;
  margin: 0.5rem 0 0 0;
  padding-left: 0;
  display: block;
  list-style: none;
}

/* layout */

#inventory-container {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  flex: auto;
}

#inventory {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: block;
  flex: auto;
  margin-top: 2rem;
}

.inventory-wrapper {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: block;
  text-align: center;
  min-height: 90vh;
}

#inventory .split {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.margin-1rem {
  margin: 1rem;
}

.margin-bottom-2rem {
  margin-bottom: 2rem;
}

@media (min-width: 1400px) {
  #inventory .split {
    flex-direction: row;
  }
  #inventory .split > * {
    flex-basis: 100%;
  }
}

#mercenaries h3 {
  padding-bottom: 0;
}

/* button */

#inventory-container button {
  color: var(--clr-primary-100);
  border: 1px solid var(--clr-primary-100);
  margin-top: 1rem;
  height: 1.5rem;
  font-size: 0.5rem;
}

#inventory-container button:hover {
  border: none;
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .inventory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/inventory.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .inventory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/inventory.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .inventory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/inventory.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .inventory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/inventory.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .inventory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/inventory.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .inventory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/inventory.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}
