.margin-right-03 {
  margin-right: 0.2rem;
  font-size: 0.8rem;
}

.visibility-toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
