#factory {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  letter-spacing: 0.05rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: smaller;
}

.factory-info-container h4 {
  color: yellow !important;
}

/* layout */
#factory {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  flex: auto;
  min-height: 90vh;
  margin-top: 5rem;
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .factory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/factory.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .factory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/factory.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .factory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/factory.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .factory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/factory.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .factory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/factory.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .factory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/factory.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}
