/* typography */
#main {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  font-weight: normal;
  letter-spacing: 0.1rem;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

/* layout */
#main {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: grid;
  flex: auto;
  margin-top: 5rem;
}

/* button */

#main button {
  color: var(--clr-primary-100);
  border: 1px solid var(--clr-primary-100);
  height: 1.5rem;
  font-size: 0.5rem;
}

#main button:hover {
  border: none;
}

/* toast */

#toast-container button {
  border: none;
}
#toast-container {
  font-weight: normal;
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  letter-spacing: 0.05rem;
  text-align: center;
}

:root {
  --toastify-color-dark: var(--clr-primary-800);
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .main-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/university.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .main-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/university.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .main-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/university.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .main-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/university.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .main-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/university.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .main-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/university.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}
