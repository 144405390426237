/* typography */

#navbar-items ul {
  padding: 1rem;
}

#navbar-items h1 {
  font-size: 1rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
}

@media screen and (min-width: 1025px) {
  #navbar-items h1 {
    font-size: 1.2rem;
    align-self: center;
  }
}

#navbar-items {
  color: var(--clr-neutral-100);
  font-family: var(--ff-primary);
  font-size: 0.6rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  text-align: center;
  text-transform: uppercase;
  background: var(--clr-primary-800);
}

#navbar-items a:hover {
  text-decoration: none;
  color: var(--clr-neutral-100) !important;
  color: inherit;
}

.menu-icon,
.rocket-icon {
  font-size: 1rem;
}

@media screen and (min-width: 1025px) {
  .menu-icon,
  .rocket-icon {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 1600px) {
  .menu-icon,
  .rocket-icon {
    font-size: 1.5rem;
  }
}

/* layout */
#navbar-wrapper {
  width: 100%;
  height: 5rem;
  z-index: 1;
}

#navbar-items {
  position: relative;
}

#navbar-items li {
  text-align: center;
  padding: 2rem;
  letter-spacing: 0.2rem;
}

.nav-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  left: -100%;
  top: 40px;
  opacity: 1;
  transition: all 0.5s ease;
}

/* .rotate {
  transition: transform 0.3s;
}

.rotate:hover {
  transform: rotate(180deg);
} */

@keyframes hinge {
  0% {
    transform: rotate(0);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    transform: rotate(80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  40% {
    transform: rotate(60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  80% {
    transform: rotate(60deg) translateY(0);
    opacity: 1;
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  100% {
    transform: translateY(700px);
    opacity: 0;
  }
}

.break {
  animation: hinge 5s;
}

.hidden-logo {
  visibility: hidden;
}

/* general */

#navbar-items {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0.5rem;
  cursor: default;
  padding-left: 2rem;
  padding-right: 2rem;
}

.nav-menu.active {
  background: rgb(1, 9, 27);
  left: 0;
  position: absolute;
  top: 40px;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 1;
  height: 100vh;
  overflow: scroll;
  padding-bottom: 5rem !important;
}
.nav-links {
  text-align: center;
  padding: 1rem;
  width: 100%;
  display: table;
  cursor: pointer;
}
.nav-links:hover {
  color: rgb(1, 9, 27);
  background-color: rgb(2, 25, 78);
}

.navbar-navlink {
  text-decoration: none;
  color: yellow;
}
.logout-button {
  text-decoration: none;
  color: rgb(235, 110, 105);
}

.logout-button:hover {
  color: white;
}

.save-button {
  text-decoration: none;
  color: rgb(125, 215, 120);
}

.save-button:hover {
  color: white;
}

.menu-icon,
.rocket-icon {
  padding: 0.5rem;
  display: block;
  cursor: pointer;
}

h1 {
  display: inline-block;
  margin: 0;
}

.nav-hidden {
  display: none;
}

/* media queries */

#navbar-items ul,
li {
  list-style: none;
}

@media screen and (min-width: 1025px) {
  .menu-icon {
    display: none;
  }

  .bg-dark {
    background: rgba(1, 9, 27, 0.25) !important;
    box-shadow: 0 8px 32px 0 rgba(1, 9, 27, 0.35) !important;
    backdrop-filter: blur(4px) !important;
    -webkit-backdrop-filter: blur(4px) !important;
  }

  .bg-transparent {
    background: transparent !important;
  }

  #navbar-items {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  #navbar-items ul {
    padding: 0.5rem;
    align-items: center;
  }

  #navbar-items li {
    padding: 1rem;
  }

  .nav-links {
    text-align: center;
    padding: 1rem;
    width: auto;
    display: table;
    cursor: pointer;
  }

  .nav-links:hover {
    background-color: transparent;
  }

  .nav-menu {
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    position: initial;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-logo {
    display: flex;
    white-space: nowrap;
  }

  .nav-logo a {
    align-self: center;
  }

  h1 {
    display: inline-block;
    text-align: center;
  }
}
@media screen and (max-width: 360px) {
  #navbar-items h1 {
    font-size: 0.6rem;
  }
}
