/* typography */

#navbar-items ul {
  padding: 1rem;
}

#navbar-items h1 {
  font-size: 1rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
}

@media screen and (min-width: 1025px) {
  #navbar-items h1 {
    font-size: 1.2rem;
    align-self: center;
  }
}

#navbar-items {
  color: var(--clr-neutral-100);
  font-family: var(--ff-primary);
  font-size: 0.6rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  text-align: center;
  text-transform: uppercase;
  background: var(--clr-primary-800);
}

#navbar-items a:hover {
  text-decoration: none;
  color: var(--clr-neutral-100) !important;
  color: inherit;
}

.menu-icon,
.rocket-icon {
  font-size: 1rem;
}

@media screen and (min-width: 1025px) {
  .menu-icon,
  .rocket-icon {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 1600px) {
  .menu-icon,
  .rocket-icon {
    font-size: 1.5rem;
  }
}

/* layout */
#navbar-wrapper {
  width: 100%;
  height: 5rem;
  z-index: 1;
}

#navbar-items {
  position: relative;
}

#navbar-items li {
  text-align: center;
  padding: 2rem;
  letter-spacing: 0.2rem;
}

.nav-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  left: -100%;
  top: 40px;
  opacity: 1;
  transition: all 0.5s ease;
}

/* .rotate {
  transition: transform 0.3s;
}

.rotate:hover {
  transform: rotate(180deg);
} */

@-webkit-keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate(80deg);
            transform: rotate(80deg);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  40% {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
            transform: rotate(60deg) translateY(0);
    opacity: 1;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: translateY(700px);
            transform: translateY(700px);
    opacity: 0;
  }
}

@keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate(80deg);
            transform: rotate(80deg);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  40% {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
            transform: rotate(60deg) translateY(0);
    opacity: 1;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: translateY(700px);
            transform: translateY(700px);
    opacity: 0;
  }
}

.break {
  -webkit-animation: hinge 5s;
          animation: hinge 5s;
}

.hidden-logo {
  visibility: hidden;
}

/* general */

#navbar-items {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0.5rem;
  cursor: default;
  padding-left: 2rem;
  padding-right: 2rem;
}

.nav-menu.active {
  background: rgb(1, 9, 27);
  left: 0;
  position: absolute;
  top: 40px;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 1;
  height: 100vh;
  overflow: scroll;
  padding-bottom: 5rem !important;
}
.nav-links {
  text-align: center;
  padding: 1rem;
  width: 100%;
  display: table;
  cursor: pointer;
}
.nav-links:hover {
  color: rgb(1, 9, 27);
  background-color: rgb(2, 25, 78);
}

.navbar-navlink {
  text-decoration: none;
  color: yellow;
}
.logout-button {
  text-decoration: none;
  color: rgb(235, 110, 105);
}

.logout-button:hover {
  color: white;
}

.save-button {
  text-decoration: none;
  color: rgb(125, 215, 120);
}

.save-button:hover {
  color: white;
}

.menu-icon,
.rocket-icon {
  padding: 0.5rem;
  display: block;
  cursor: pointer;
}

h1 {
  display: inline-block;
  margin: 0;
}

.nav-hidden {
  display: none;
}

/* media queries */

#navbar-items ul,
li {
  list-style: none;
}

@media screen and (min-width: 1025px) {
  .menu-icon {
    display: none;
  }

  .bg-dark {
    background: rgba(1, 9, 27, 0.25) !important;
    box-shadow: 0 8px 32px 0 rgba(1, 9, 27, 0.35) !important;
    backdrop-filter: blur(4px) !important;
    -webkit-backdrop-filter: blur(4px) !important;
  }

  .bg-transparent {
    background: transparent !important;
  }

  #navbar-items {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  #navbar-items ul {
    padding: 0.5rem;
    align-items: center;
  }

  #navbar-items li {
    padding: 1rem;
  }

  .nav-links {
    text-align: center;
    padding: 1rem;
    width: auto;
    display: table;
    cursor: pointer;
  }

  .nav-links:hover {
    background-color: transparent;
  }

  .nav-menu {
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    position: static;
    position: initial;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-logo {
    display: flex;
    white-space: nowrap;
  }

  .nav-logo a {
    align-self: center;
  }

  h1 {
    display: inline-block;
    text-align: center;
  }
}
@media screen and (max-width: 360px) {
  #navbar-items h1 {
    font-size: 0.6rem;
  }
}

.modal-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
  cursor: pointer;
}

.padding-1rem {
  padding: 1rem;
}

@media screen and (max-width: 960px) {
  .text-description {
    border-left: none;
  }
}

.inline-block {
  display: inline-block;
}

.text-description {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 960px) {
  .text-description {
    margin-bottom: 0 !important;
    letter-spacing: 0.06rem;
    margin-right: auto;
    text-align: justify;
    justify-content: center;
    align-content: center;
    align-self: center;
  }

  .underline {
    width: 65%;
    height: 0.1rem;
    margin-left: -2rem;
    background-color: white;
    margin-bottom: 25px;
  }

  .content {
    width: 80%;
    margin-left: 5rem;
    margin-right: 3rem;
  }

  .title {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 480px) {
  .underline {
    width: 70%;
    margin-bottom: 25px;
  }
}

.margin-top-05rem img {
  margin-top: 0.5rem;
}

.placeholder {
  height: 3.5rem;
}

@media screen and (max-width: 480px) {
  .desktop {
    display: none;
  }
  .placeholder {
    display: none;
  }
}

.current-planet {
  color: yellow;
}

.sidebar li {
  margin: 0.5rem;
  text-transform: uppercase;
}
@media screen and (min-width: 480) {
  .sidebar {
    display: block !important;
    flex-direction: row !important;
  }
}

.sidebar li {
  text-align: center;
}

@media screen and (min-width: 720px) {
  .sidebar {
    display: flex;
    flex-direction: row !important;
    padding: 3rem !important;
    margin-bottom: 0 !important;
    letter-spacing: 0.05rem;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    justify-content: center;
    align-content: right;
    align-self: center;
  }
}

@media screen and (max-width: 480px) {
  .sidebar {
    display: block;
    flex-direction: column;
    border-right: none;
    text-align: center;
  }
}

/* typography */
.planet-container h3 {
  color: var(--clr-primary-100);
}
#planet {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  letter-spacing: 0.05rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: smaller;
}

#planet h2 {
  font-size: 2rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 0.6rem;
}

#planet h3 {
  font-size: 1rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: yellow;
  letter-spacing: 0.3rem;
}
#planet h4 {
  font-size: 1rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 0.3rem;
}
.planet-info-container h4 {
  color: yellow !important;
}

.color-win {
  color: greenyellow;
}
.color-lose {
  color: red;
}

.visited {
  color: var(--clr-neutral-300);
}

/* layout */
#planet {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  min-height: 90vh;
  margin-top: 5rem;
}
.flex-auto {
  flex: auto;
}

@media screen and (min-width: 960px) {
  .planet-stats-first {
    margin-left: 5rem;
    margin-right: 1rem;
  }
  .planet-stats-middle {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .planet-stats-last {
    margin-left: 1rem;
    margin-right: 5rem;
  }
}

@media screen and (min-width: 1024px) {
  .planet-activities-container {
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 960px) {
  .planet-activities-container {
    width: min(80%, 70rem);
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .planet-info-container {
    bottom: 2rem;
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }
  .map-header {
    margin-bottom: 5rem;
    width: min(40%, 70rem);
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
  }
}
.flex-centered {
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  justify-content: center;
  justify-items: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.planet-content-container {
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: min(100%, 70rem);
  padding-bottom: auto;
  text-align: center;
}

.header-container {
  margin-bottom: 3rem;
}

.planet-container {
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding-bottom: auto;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.planet-container article article {
  text-align: center;
}
.desktop {
  display: none;
}

@media screen and (min-width: 960px) {
  .planet-container {
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: flex-start;
    border-radius: 10px;
  }
  .mobile {
    display: none;
  }
  .desktop {
    display: contents;
  }
}
.width-80 {
  width: min(95%, 70rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.planet-split {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 1020px) {
  .planet-split {
    margin-bottom: 5rem;
  }
}

.planet-split {
  margin-bottom: 3rem;
}

@media (min-width: 960px) {
  .planet-split {
    flex-direction: row;
  }
  .planet-split > * {
    flex-basis: 100%;
  }
}
@media screen and (min-width: 480px) {
  .width-80 {
    width: min(80%, 70rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 960px) {
  #planet h2 {
    align-self: flex-start;
    justify-self: left;
    margin-right: -100px;
  }
}

@media screen and (min-width: 960px) {
  .border {
    border-bottom: none;
  }
}

.not-found p {
  margin-bottom: 0;
}

.border-radius {
  border-radius: 10px;
}

.padding-places {
  padding: 1rem;
}

.padding-botton-3rem {
  padding-bottom: 3rem;
}

.padding-1rem {
  padding: 1rem;
}

.padding-3rem {
  padding: 3rem;
}

.margin-1rem {
  margin: 1rem;
}

.margin-block-planet-container {
  margin: auto;
}

/* carousel customization */

#mercenaries-list .alice-carousel__wrapper {
  height: 450px !important;
}

#mercenaries .alice-carousel__wrapper {
  height: 450px !important;
}

.alice-carousel__wrapper {
  height: 350px !important;
}

@media screen and (min-width: 1024px) {
  .alice-carousel__wrapper {
    height: 370px !important;
  }
}

@media screen and (min-width: 2200px) {
  .alice-carousel__wrapper {
    height: 450px !important;
  }
}

@media screen and (min-width: 1020px) and (max-height: 780px) {
  .alice-carousel__wrapper {
    height: 320px !important;
  }
}

@media screen and (max-width: 960px) {
  .alice-carousel__wrapper {
    height: 300px !important;
  }
}

.carousel-card {
  margin: 0.5rem;
  border-radius: 15px;
}

.alice-carousel {
  padding-bottom: 2rem;
  color: inherit;
}

.alice-carousel__stage-item {
  display: inline-flex !important;
  justify-content: center !important;
}

.alice-carousel__next-btn {
  text-align: left;
}

.alice-carousel__prev-btn {
  text-align: right;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  color: white !important;
}

.alice-carousel__prev-btn [data-area]::after {
  content: "< prev";
  font-size: 1rem;
  color: white;
}

.alice-carousel__next-btn [data-area]::after {
  content: "next >";
  font-size: 1rem;
  color: white;
}

@media screen and (max-width: 1024px) {
  .carousel-card {
    background-color: transparent;
  }
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  color: white;
  font-weight: 500;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.carousel-card {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.carousel-card:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

/* background images */

@media screen and (min-width: 1281px) {
  .no-webp .crion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/crion.3664c9af.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .crion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/crion.1165fbed.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .therion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/therion.073e4b32.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .therion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/therion.1868493f.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .crystalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/crystalia.08f55de1.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .crystalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/crystalia.57fa3e0b.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .desertia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/desertia.283e2c91.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .desertia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/desertia.a6aacbac.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .xillon-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/xillon.06584f86.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .xillon-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/xillon.f44cffb5.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .axios-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/axios.6e256bef.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .axios-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/axios.95917f12.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .thalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/thalia.c6b9b4e8.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .thalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/thalia.60d95f3d.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .bathea-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/bathea.e91c8bf0.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .bathea-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/bathea.65f583cb.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .centuria-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/centuria.75678c62.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .centuria-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/centuria.4a8264b8.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .menu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/menu.a515f1e5.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .menu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/menu.874df8db.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .ufo-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/ufo.00ee21a3.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .ufo-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/ufo.a8135ed0.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .crion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/crion.b0f8e7b9.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .crion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/crion.4861e40f.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .therion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/therion.209062f3.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .therion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/therion.33716441.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .crystalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/crystalia.0c8f3814.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .crystalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/crystalia.bf26eee1.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .desertia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/desertia.3950de9d.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .desertia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/desertia.5242c31b.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .xillon-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/xillon.3d879922.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .xillon-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/xillon.8e8b2f84.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .axios-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/axios.33403dce.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .axios-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/axios.86640067.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .thalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/thalia.625fb4f6.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .thalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/thalia.c19c11b4.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .bathea-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/bathea.b0eb2315.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .bathea-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/bathea.5155159e.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .centuria-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/centuria.e7db8634.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .centuria-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/centuria.e3ed74b8.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .menu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/menu.f0f8f029.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .menu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/menu.6d44f731.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .ufo-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/ufo.f6220a29.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .ufo-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/ufo.427b6f85.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .crion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/crion.6d3635bb.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .crion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/crion.36af6594.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .therion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/therion.a8659774.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .therion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/therion.0309fb43.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .crystalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/crystalia.8f765d91.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .crystalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/crystalia.0685b068.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .desertia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/desertia.14c21e90.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .desertia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/desertia.e118410f.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .xillon-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/xillon.8bf862e0.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .xillon-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/xillon.56b599d0.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .axios-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/axios.feeadabb.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .axios-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/axios.c2666dad.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .thalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/thalia.cf83c29e.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .thalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/thalia.8f7667fb.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .bathea-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/bathea.0a33659a.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .bathea-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/bathea.2beabbae.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .centuria-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/centuria.c0620f4c.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .centuria-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/centuria.6f2a292d.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .menu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/menu.8f43178e.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .menu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/menu.735e69af.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .ufo-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/ufo.64a13c92.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .ufo-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/ufo.8663c0d7.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

:root {
  --clr-primary-000: transparent;
  --clr-primary-100: #ffff;
  --clr-primary-200: #163761be;
  --clr-primary-300: #236f8a;
  --clr-primary-500: #58a7f7;
  --clr-primary-800: rgb(1, 9, 27);
  --clr-primary-900: black;

  --clr-warning: rgb(235, 110, 105);
  --clr-yellow: yellow;
  --clr-green: rgb(125, 215, 120);
  --clr-neutral-100: white;
  --clr-neutral-200: #929191;
  --clr-neutral-300: rgb(180, 180, 180);

  --ff-primary: "Futura", sans-serif;
  --ff-secondary: "Source Sans Pro", Arial, sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* typography */

html {
  font-size: 100%;
}

body {
  font-size: 1rem;
  font-weight: 300;
  font-family: "Futura", sans-serif;
  font-family: var(--ff-primary);
  color: #ffff;
  color: var(--clr-primary-100);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

.place {
  font-family: "Futura", sans-serif;
  font-family: var(--ff-primary);
  color: #ffff;
  color: var(--clr-primary-100);
  letter-spacing: 0.05rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: smaller;
}

h2 {
  font-size: 2rem;
  font-weight: 200;
  font-family: "Futura", sans-serif;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 0.6rem;
}

h3 {
  font-size: 1rem;
  font-weight: 200;
  font-family: "Futura", sans-serif;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 0.3rem;
}
h4 {
  font-size: 1rem;
  font-weight: 200;
  font-family: "Futura", sans-serif;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: yellow;
  color: var(--clr-yellow);
  letter-spacing: 0.3rem;
}

input {
  text-align: center;
  background-color: white;
  color: rgb(1, 9, 27);
  color: var(--clr-primary-800);
  display: inline-block;
  height: 1.5rem;
  font-size: 0.8rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  border: none;
  height: 35px;
  padding: 0.5rem 0;
  border-radius: 16px;
  margin: 0.5rem 0;
}

.warning {
  background-color: rgb(235, 110, 105) !important;
  background-color: var(--clr-warning) !important;
}

.warning:hover {
  background-color: white !important;
}

.caution {
  background-color: yellow !important;
  background-color: var(--clr-yellow) !important;
}

.caution:hover {
  background-color: white !important;
}

#footer {
  font-family: "Futura", sans-serif;
  font-family: var(--ff-primary);
  font-size: 0.5rem;
  color: white;
  color: var(--clr-neutral-100);
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-align: center;
}
#footer > h3 {
  font-size: 0.6rem;
}

#copyright {
  color: white;
  color: var(--clr-neutral-100);
  font-family: "Futura", sans-serif;
  font-family: var(--ff-primary);
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  line-height: 1.5;
}

button {
  font-family: "Futura", sans-serif;
  font-family: var(--ff-primary);
  color: white;
  color: var(--clr-neutral-100);
  font-weight: 900 !important;
  letter-spacing: 0.1rem !important;
  background-color: #63a8c7;
  height: 1.5rem !important;
  font-size: 0.5rem !important;
}

.back-button {
  margin: 1rem 0 0 0;
  cursor: pointer;
}

.warning {
  background-color: rgb(235, 110, 105) !important;
  background-color: var(--clr-warning) !important;
}

.warning:hover {
  background-color: white !important;
}

.text-center {
  text-align: center;
}
.place-description {
  color: yellow;
  font-weight: normal;
  padding: 0.5rem;
}

.carousel-card img {
  margin: 0.5rem !important;
}

.carousel-card {
  max-width: 250px;
  height: 420px;
}
.alice-carousel {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

@media screen and (min-width: 420px) {
  html {
    font-size: 90%;
  }
}

@media screen and (min-width: 1020px) {
  html {
    font-size: 120%;
  }
}
@media screen and (min-width: 1020px) and (max-height: 780px) {
  html {
    font-size: 90%;
  }
}
@media screen and (min-width: 2200px) {
  html {
    font-size: 150%;
  }
}

/* general */

@media screen and (max-width: 480px) {
  html,
  body {
    min-width: 320px;
  }
}

html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

body,
h2,
h3,
p {
  margin: 0;
}

h2,
p {
  margin-bottom: 1rem;
}

img {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

ol,
ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.flex {
  display: flex;
}

.dialogue-line {
  border-left: 0;
  margin: 1rem 0 1rem 0 !important;
  padding-left: 0;
  display: block !important;
  list-style: none;
  white-space: pre-line;
}

.cursor-pointer {
  cursor: pointer;
}

.flex * {
  padding: 0.25rem;
}

.padding-4rem {
  padding: 4rem;
}

.padding-1rem {
  padding: 1rem;
}

.margin-inline-1rem {
  margin-inline: 1rem;
}

.margin-1rem {
  margin: 1rem;
}

.no-padding-top {
  padding-top: 0 !important;
}

#container {
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-inline: auto;
}

a {
  color: inherit;
}

/* layout */

.section-split {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: nowrap;
}

@media screen and (min-width: 1020px) {
  .section-split {
    margin-bottom: 5rem;
  }
}

@media (min-width: 480px) {
  .section-split {
    flex-direction: row;
  }
  .section-split > * {
    flex-basis: 100%;
  }
}

@media screen and (max-width: 640px) {
  .section-split {
    flex-wrap: wrap;
  }
}

.split {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (min-width: 1024px) {
  .split {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
}

.flex-auto {
  flex: auto;
}

.main-wrapper {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: block;
  flex-direction: column;
}

.main-wrapper ul li {
  border-left: 0;
  margin: 0.5rem 0 0 0;
  padding-left: 0;
  display: block;
  list-style: none;
}

.place {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  flex: auto;
  display: flex;
  margin-top: 5rem;
}

.place input {
  width: 150px;
  background-color: white !important;
  color: rgb(1, 9, 27) !important;
  color: var(--clr-primary-800) !important;
  display: inline-block;
  height: 1.5rem;
  font-size: 0.8rem;
  padding: 0 0.5rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 15px;
  border: none !important;
  margin: 0.5rem 0.5rem 1.5rem 0.5rem;
}

.place p {
  margin-bottom: 1rem;
}

.scroll {
  overflow: scroll;
}

/* button */
.place button {
  color: #ffff;
  color: var(--clr-primary-100);
  border: 1px solid #ffff;
  border: 1px solid var(--clr-primary-100);
  height: 1.5rem;
  font-size: 0.6rem;
  padding: 0 1rem;
  margin: 0.5rem 0 !important;
}

.place button:hover {
  border: none;
}

@media screen and (min-width: 960px) {
  .place h2 {
    align-self: flex-start;
    justify-self: left;
    margin-right: -100px;
  }
}

.desktop {
  display: none;
}

.win {
  color: greenyellow;
}
.lose {
  color: red;
}

.header-container {
  margin-bottom: 3rem;
}

@media screen and (min-width: 480px) {
  .align-self-flex-start {
    align-self: flex-start;
  }
  .margin-bottom-0 {
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 960px) {
  .border {
    border-bottom: none;
  }
}

.not-found p {
  margin-bottom: 0;
}

.border-radius {
  border-radius: 10px;
}

.padding-places {
  padding: 1rem;
}

.padding-botton-3rem {
  padding-bottom: 3rem;
}

.padding-bottom-05rem {
  padding-bottom: 0.5rem;
}

.padding-1rem {
  padding: 1rem;
}
.margin-top-1-5rem {
  margin-top: 1.5rem !important;
}

.centered-2rem {
  margin: 0 3rem;
}

.padding-3rem {
  padding: 3rem;
}

.margin-1rem {
  margin: 1rem;
}
.place .button-margin {
  margin: 0.5rem auto !important;
}

.margin-block-alchemist-container {
  margin: auto;
}
.margin-0-3rem {
  margin: 0.3rem !important;
}

.margin-bottom-2rem {
  margin-bottom: 2rem !important;
}
.labels-container {
  display: flex;
}

.centered {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.labels {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.article-width {
  margin: auto;
  padding: 1rem;
}

@media screen and (min-width: 480px) {
  .article-width {
    width: 80%;
    padding: 2rem;
  }
}

@media screen and (min-width: 960px) {
  .article-width {
    width: 60%;
    padding: 2rem;
  }
}

/* button */
.button {
  background-color: white !important;
  color: rgb(1, 9, 27) !important;
  cursor: pointer;
  display: inline-block;
  height: 1.5rem;
  font-size: 0.6rem;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  border-radius: 15px;
  border: none !important;
  margin: 0.5rem;
}
.button:hover {
  border: none !important;
  text-decoration: none;
  background-color: yellow !important;
}

.button.small {
  font-size: 0.4rem;
  height: 1.4rem;
  line-height: 1.2rem;
  padding: 0 0.5rem;
}

.button.large {
  font-size: 0.6rem;
  height: 1.8rem;
  line-height: 1.5rem;
  padding: 0 0.8rem;
}

.menu-button {
  color: #ffff;
  color: var(--clr-primary-100);
  border: 1px solid #ffff;
  border: 1px solid var(--clr-primary-100);
  width: 100%;
  margin: 0.5rem 0 !important;
  height: 35px !important;
  padding: 0.5rem 0;
  border-radius: 16px;
  font-size: 0.6rem !important;
}

.modal-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
  cursor: pointer;
}

.modal-description {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.button:hover {
  border: none;
}
.button a {
  display: block;
}

#confirmationModal .bg-green {
  background-color: rgb(125, 215, 120) !important;
}
#confirmationModal .bg-green:hover {
  background-color: white !important;
}

#confirmationModal .bg-red {
  background-color: rgb(235, 110, 105) !important;
}
#confirmationModal .bg-red:hover {
  background-color: white !important;
}

a {
  display: block;
}
.cubical {
  border-radius: 5px;
}

.no-decoration {
  text-decoration: none;
}

.list-horizontal {
  margin: 0 !important;
  padding-left: 0;
  list-style: none;
}

.list-horizontal li {
  border-left: 0 !important;
  margin: 0.2rem !important;
  display: inline-block !important;
  letter-spacing: 0.05rem !important;
}

.dark-transparent-bg {
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.6);
}

/* modal */
.ReactModalPortal ul,
.ReactModalPortal ul li {
  border-left: 0;
  margin: 1rem 0 0 0;
  padding-left: 0;
  display: block;
  letter-spacing: 0.05rem;
}
.ReactModalPortal ul {
  margin: 3rem;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 100ms;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

/* scroll */

html {
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

/* other */

.multi-line-toast {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.link-button {
  display: inline;
}

.button {
  background-color: white !important;
  color: rgb(1, 9, 27) !important;
  cursor: pointer;
  display: inline-block;
  height: 1.5rem;
  font-size: 0.6rem;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  border-radius: 15px;
  border: none !important;
  margin: 0.5rem;
}

.button:hover {
  border: none !important;
  text-decoration: none;
  background-color: yellow !important;
}

.button.small {
  font-size: 0.4rem;
  height: 1.4rem;
  line-height: 1.2rem;
  padding: 0 0.5rem;
}

.button.large {
  font-size: 0.6rem;
  height: 1.8rem;
  line-height: 1.5rem;
  padding: 0 0.8rem;
}

/* button */
.button {
  background-color: white !important;
  color: rgb(1, 9, 27) !important;
  cursor: pointer;
  display: inline-block;
  height: 1.5rem;
  font-size: 0.6rem;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  border-radius: 15px;
  border: none !important;
  margin: 0.5rem;
}
.button:hover {
  border: none !important;
  text-decoration: none;
  background-color: yellow !important;
}

.button.small {
  font-size: 0.4rem;
  height: 1.4rem;
  line-height: 1.2rem;
  padding: 0 0.5rem;
}

.button.large {
  font-size: 0.6rem;
  height: 1.8rem;
  line-height: 1.5rem;
  padding: 0 0.8rem;
}

.button-margin {
  margin: 0.5rem auto !important;
}

.cursor-pointer {
  cursor: pointer;
}

#footer {
  padding: 1rem;
  background: transparent;
  margin-top: -5rem;
  z-index: 99999;
}

#footer > h3 {
  color: var(--clr-neutral-100) !important;
  letter-spacing: 0.3rem;
}

.thumbnail img {
  width: 50% !important;
  border-radius: 15px;
}

@media screen and (max-width: 480px) {
  .thumbnail img {
    width: 90% !important;
  }
}

/* typography */
.welcome-container h3 {
  color: var(--clr-primary-100);
}
#welcome {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  letter-spacing: 0.05rem;
  text-align: center;
  font-size: smaller;
}

#welcome h2 {
  font-size: 2rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 0.6rem;
}

#welcome h3 {
  font-size: 1rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: yellow;
  letter-spacing: 0.3rem;
}
#welcome h4 {
  font-size: 1rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 0.3rem;
}
.welcome-info-container h4 {
  color: yellow !important;
}

/* layout */

#intro {
  padding: 0 1rem 0 1rem;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  -moz-justify-content: -moz-flex-end;
  -ms-justify-content: -ms-flex-end;
  justify-content: flex-end;
  position: relative;
  cursor: default;
  text-align: center;
  min-height: 100vh;
  margin-top: 5rem;
}

#welcome {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-bottom: 3rem;
}

@media (min-width: 1025px) {
  .intro-split {
    display: flex;
    flex-direction: row !important;
  }
}

@media (max-width: 1024px) {
  .menu-title h2 {
    font-size: 1.5rem !important;
    font-weight: 200;
    font-family: var(--ff-primary);
    text-transform: uppercase;
    letter-spacing: 0.6rem;
  }

  .menu-title .underline {
    width: 60%;
  }
}

@media (min-width: 480px) {
  .intro-split > * {
    flex-basis: 100%;
  }
}

@media (min-width: 1025px) {
  #welcome .planet-split {
    flex-direction: row !important;
  }
}

.shadow-bg {
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.4);
}

.gallery-split {
  display: flex;
  flex-direction: column;
}

@media (min-width: 480px) {
  .gallery-split {
    flex-direction: row !important;
  }
}

@media (min-width: 480px) {
  #welcome .planet-split > * {
    flex-basis: 100%;
  }
}

@media screen and (max-width: 1024px) {
  #intro article {
    margin-bottom: 5rem;
  }
}

@media screen and (max-width: 1024px) {
  #welcome .underline-places {
    width: 30% !important;
    justify-self: center;
    align-self: center;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 640px) {
  #welcome .underline-places {
    width: 60% !important;
  }
}

#welcome .underline-places {
  width: 60%;
}

#welcome .places-header {
  width: 100%;
}

#welcome .header-container {
  padding-top: 1rem !important;
  padding-bottom: 3rem !important;
}

@media (min-width: 1025px) {
  #welcome .header-container {
    padding-top: 1rem !important;
    padding-bottom: 5rem !important;
  }
}

.menu-navbar {
  width: 100%;
}

.menu-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media screen and (min-width: 960px) {
  .content {
    width: 80%;
    margin-left: 5rem;
    margin-right: 3rem;
  }
}

/* general */

#welcome > h2 {
  padding-bottom: 2rem;
}

#welcome p {
  margin: 1rem;
}

#welcome > a {
  color: inherit;
}

#welcome > form {
  padding: 1rem;
}

#welcome {
  color: var(--clr-neutral-100);
  margin-block: auto;
}
#welcome .border {
  border: 1px solid #e1f5fce1 !important;
}

#welcome .welcome-form {
  align-content: center;
  justify-content: center;
  display: grid;
}

.menu-nav {
  display: inline-flex;
  padding-bottom: 1.5rem;
}

.no-decoration {
  text-decoration: none;
}

@media screen and (min-width: 1025px) {
  #welcome .places-header {
    width: 100%;
    flex-direction: column !important;
    margin-bottom: 1rem;
  }
}

/* arrow link*/

.nav-arrow {
  display: inline-block;
}

.nav-arrow:hover {
  color: yellow;
}

@media screen and (max-width: 480px) {
  .menu-nav {
    flex-direction: column;
    padding-top: 1.5rem;
  }
}

@media screen and (max-width: 960px) {
  .planet-description {
    border-left: none;
  }
}
.width-80 {
  width: min(80%, 70rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.inline-block {
  display: inline-block;
}

.planet-description {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 960px) {
  .border {
    border-bottom: none;
  }
}

.rotate180deg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.not-found p {
  margin-bottom: 0;
}

.border-radius {
  border-radius: 10px;
}

.padding-places {
  padding: 1rem;
}

.padding-botton-3rem {
  padding-bottom: 3rem;
}

.padding-1rem {
  padding: 1rem;
}

.padding-3rem {
  padding: 3rem;
}

.padding-2rem {
  padding: 2rem;
}

.margin-1rem {
  margin: 1rem;
}

#welcome .button-margin {
  margin: 0.5rem 0.5rem !important;
}
#welcome .button {
  margin: 0.5rem 1rem;
  font-size: 0.6rem !important;
}

#welcome .colors-inverted {
  background-color: yellow !important;
}

#welcome .colors-inverted:hover {
  background-color: white !important;
}

.margin-block-planet-container {
  margin: auto;
}

#modal .bg-green {
  background-color: rgb(125, 215, 120) !important;
}
#modal .bg-green:hover {
  background-color: white !important;
}

#modal .bg-red {
  background-color: rgb(235, 110, 105) !important;
}
#modal .bg-red:hover {
  background-color: white !important;
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .welcome-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/planet.80472635.webp) no-repeat;
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .welcome-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/planet.80472635.webp) no-repeat;
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}
@media screen and (max-width: 1280px) {
  .no-webp .welcome-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/planet.15ea94c1.webp) no-repeat;
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .welcome-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/planet.15ea94c1.webp) no-repeat;
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .welcome-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/planet.28dc81e1.webp) no-repeat;
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .welcome-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/planet.28dc81e1.webp) no-repeat;
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

/* layout */
#notFound {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
}

/* image */
.not-found-logo {
  width: 80% !important;
}

@media screen and (min-width: 420px) {
  .not-found-logo {
    width: 60% !important;
  }
}

@media screen and (min-width: 960px) {
  .not-found-logo {
    width: 40% !important;
  }
}

.ufo {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s, visibility 0.5s;
}

.ufo-container:hover .ufo {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s, visibility 2s;
}
/* background */
@media screen and (min-width: 1281px) {
  .no-webp .notFound-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/ufo.00ee21a3.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .notFound-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/ufo.a8135ed0.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .notFound-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/ufo.f6220a29.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .notFound-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/ufo.427b6f85.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .notFound-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/ufo.64a13c92.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .notFound-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/ufo.8663c0d7.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

.dialogue-line {
  border-left: 0;
  margin: 1rem 0 1rem 0 !important;
  padding-left: 0;
  display: block !important;
  list-style: none;
  white-space: pre-line;
}

.visited {
  color: var(--clr-neutral-300);
}

.cursor-pointer {
  cursor: pointer;
}

/* button */
.button {
  background-color: white !important;
  color: rgb(1, 9, 27) !important;
  cursor: pointer;
  display: inline-block;
  height: 1.5rem;
  font-size: 0.6rem;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  border-radius: 15px;
  border: none !important;
  margin: 0.5rem;
}
.button:hover {
  border: none !important;
  text-decoration: none;
  background-color: yellow !important;
}

.button.small {
  font-size: 0.4rem;
  height: 1.4rem;
  line-height: 1.2rem;
  padding: 0 0.5rem;
}

.button.large {
  font-size: 0.6rem;
  height: 1.8rem;
  line-height: 1.5rem;
  padding: 0 0.8rem;
  font-size: 0.6rem !important;
}

.modal-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
}

.modal-description {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.button:hover {
  border: none;
}
.button a {
  display: block;
}
a {
  display: block;
}

#questions .dialogue-line {
  border-left: 0;
  margin: 1rem 0 1rem 0 !important;
  padding-left: 0;
  display: block !important;
  list-style: none;
  white-space: pre-line;
}

#answers .dialogue-line {
  border-left: 0;
  margin: 1rem 0 1rem 0 !important;
  padding-left: 0;
  display: block !important;
  list-style: none;
  white-space: pre-line;
}

.visited {
  color: var(--clr-neutral-300);
}

.cursor-pointer {
  cursor: pointer;
}

#closeQuestionsLine {
  border-left: 0 !important;
  margin: 0.2rem !important;
  display: inline-block !important;
  letter-spacing: 0.05rem !important;
  margin-top: 1rem !important;
}

img {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.logo img {
  width: 25% !important;
}

@media screen and (min-width: 1920px) {
  .logo img {
    width: 20% !important;
  }
}

.container-split {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

@media screen and (min-width: 1020px) {
  .container-split {
    margin-bottom: 5rem;
  }
}

@media (min-width: 480px) {
  .container-split {
    flex-direction: row;
  }
  .container-split > * {
    flex-basis: 100%;
  }
}

@media screen and (min-width: 480px) {
  .underline-places {
    width: 70%;
    margin-top: 1rem;
  }
}

@media screen and (min-width: 960px) {
  .underline-places {
    width: 20%;
    height: 0.1rem;
    margin-right: -2rem;
    background-color: white;
    align-self: center;
    margin-left: 1rem;
    margin-top: 0;
  }
}

@media screen and (min-width: 960px) {
  .places-header {
    display: flex;
    width: min(60%, 70rem);
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .places-header h1,
  .places-header h2,
  .places-header h3,
  .places-header h4,
  .places-header h5 {
    justify-self: center;
  }
}

#factory {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  letter-spacing: 0.05rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: smaller;
}

.factory-info-container h4 {
  color: yellow !important;
}

/* layout */
#factory {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  flex: auto;
  min-height: 90vh;
  margin-top: 5rem;
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .factory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/factory.3ad4a449.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .factory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/factory.cf9669d4.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .factory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/factory.1690986b.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .factory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/factory.a5651998.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .factory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/factory.b62c8228.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .factory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/factory.c5982827.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

/* typography */
.planet-container h3 {
  color: var(--clr-primary-100);
}
#shop {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  letter-spacing: 0.05rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: smaller;
}

.shop-info-container h4 {
  color: yellow !important;
}

/* layout */
#shop {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  flex: auto;
  display: flex;
  min-height: 90vh;
  margin-top: 5rem;
}

.shop-wrapper {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: block;
  flex-direction: column;
}

#shop p {
  margin-bottom: 1rem;
}

#shop .button-margin {
  margin: 0.5rem auto !important;
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .shop-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/shop.6eb64d63.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .shop-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/shop.dec8ab91.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .shop-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/shop.8de98609.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .shop-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/shop.609747fd.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .shop-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/shop.2cfb2506.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .shop-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/shop.c171dcf5.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

/* thumbnails */
#mercenaries-list .thumbnail img {
  width: 60% !important;
  border-radius: 15px;
}

@media screen and (max-width: 480px) {
  #mercenaries-list .thumbnail img {
    width: 80% !important;
  }
}

@media screen and (min-width: 480px) {
  #mercenaries-list .thumbnail img {
    max-width: 200px;
  }
}

#mercenaries-list .thumbnail img {
  cursor: pointer;
}

/* typography */
#inventory {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  font-weight: normal;
  letter-spacing: 0.1rem;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

#inventory-container h3 {
  padding: 1rem;
  font-size: 1rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 0.3rem;
  color: yellow !important;
}

#inventory-container h4 {
  font-size: 1rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 0.3rem;
}

#inventory ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

#inventory ul li,
#mercenaries-list ul li {
  border-left: 0;
  margin: 0.5rem 0 0 0;
  padding-left: 0;
  display: block;
  list-style: none;
}

/* layout */

#inventory-container {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  flex: auto;
}

#inventory {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: block;
  flex: auto;
  margin-top: 2rem;
}

.inventory-wrapper {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: block;
  text-align: center;
  min-height: 90vh;
}

#inventory .split {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.margin-1rem {
  margin: 1rem;
}

.margin-bottom-2rem {
  margin-bottom: 2rem;
}

@media (min-width: 1400px) {
  #inventory .split {
    flex-direction: row;
  }
  #inventory .split > * {
    flex-basis: 100%;
  }
}

#mercenaries h3 {
  padding-bottom: 0;
}

/* button */

#inventory-container button {
  color: var(--clr-primary-100);
  border: 1px solid var(--clr-primary-100);
  margin-top: 1rem;
  height: 1.5rem;
  font-size: 0.5rem;
}

#inventory-container button:hover {
  border: none;
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .inventory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/inventory.4e6757c7.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .inventory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/inventory.959876f0.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .inventory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/inventory.79d3fea0.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .inventory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/inventory.640fed81.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .inventory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/inventory.5ce4dd6d.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .inventory-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/inventory.e69fff13.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

#favorites {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: flex;
  flex: auto;
  margin-top: 5rem;
}

#favorites h3 {
  color: yellow !important;
}

.favorites-wrapper {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.favorites-container {
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: min(80%, 70rem);
  padding-bottom: auto;
  text-align: center;
}

/* button */

#favorites button {
  color: var(--clr-primary-100);
  border: 1px solid var(--clr-primary-100);
  margin-top: 1rem;
  height: 1.5rem;
  font-size: 0.5rem;
}

#favorites button:hover {
  border: none;
}

.fav-del-button {
  margin: 0.5rem;
}

.fav-title {
  display: inline-flex;
  align-items: center;
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .favorites-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/astronomy.6bf96bad.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .favorites-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/astronomy.c43b3b9e.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .favorites-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/astronomy.4e82e267.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .favorites-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/astronomy.8228d934.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .favorites-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/astronomy.26fbe212.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .favorites-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/astronomy.f3a84bb7.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

.definition-search-info-container h4 {
  color: yellow !important;
}

@media screen and (min-width: 960px) {
  .content {
    width: 80%;
    margin-left: 5rem;
    margin-right: 3rem;
  }
}

#definition-search .button-margin {
  margin: 0.5rem auto !important;
}

.logo img {
  width: 35% !important;
}

@media screen and (min-width: 420px) {
  .logo img {
    width: 30% !important;
  }
}
@media screen and (max-width: 420px) {
  .logo img {
    width: 30% !important;
  }
}

/* typography */
.planet-container h3 {
  color: var(--clr-primary-100);
}
#quiz {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  letter-spacing: 0.05rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: smaller;
}

/* layout */
#quiz {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  flex: auto;
  display: flex;
  min-height: 90vh;
  margin-top: 5rem;
}

.quiz-wrapper {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#quiz form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#quiz ul,
li {
  border-left: 0;
  padding-left: 0;
  display: block;
  list-style: none;
}

.padding-05rem {
  padding: 0.5rem;
}

@media screen and (min-width: 960px) {
  .content {
    width: 80%;
    margin-left: 5rem;
    margin-right: 3rem;
  }
}
.quiz-container {
  display: flex;
  height: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.flex-column {
  flex-direction: column;
}

.quiz-split {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 1020px) {
  .quiz-split {
    margin-bottom: 5rem;
  }
}

@media (min-width: 480px) {
  .quiz-split {
    flex-direction: row;
  }
  .quiz-split > * {
    flex-basis: 100%;
  }
}
@media screen and (min-width: 480px) {
  .align-self-flex-start {
    align-self: flex-start;
  }
  .margin-bottom-0 {
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 960px) {
  #quiz h2 {
    align-self: flex-start;
    justify-self: left;
    margin-right: -100px;
  }
}

@media screen and (min-width: 960px) {
  .border {
    border-bottom: none;
  }
}

#quiz p {
  margin-bottom: 1rem;
}

.not-found p {
  margin-bottom: 0;
}

#quiz .button-margin {
  margin: 0.5rem auto !important;
}

.margin-block-quiz-container {
  margin: auto;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* typography */
#main {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  font-weight: normal;
  letter-spacing: 0.1rem;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

/* layout */
#main {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: grid;
  flex: auto;
  margin-top: 5rem;
}

/* button */

#main button {
  color: var(--clr-primary-100);
  border: 1px solid var(--clr-primary-100);
  height: 1.5rem;
  font-size: 0.5rem;
}

#main button:hover {
  border: none;
}

/* toast */

#toast-container button {
  border: none;
}
#toast-container {
  font-weight: normal;
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  letter-spacing: 0.05rem;
  text-align: center;
}

:root {
  --toastify-color-dark: var(--clr-primary-800);
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .main-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/university.58680f95.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .main-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/university.7a687b38.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .main-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/university.677e3e44.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .main-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/university.abe1ac05.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .main-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/university.2c83aff3.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .main-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/university.a0c05f3b.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (min-width: 1281px) {
  .no-webp .casino-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/casino.b94ad4c4.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .casino-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/casino.3dedf462.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}
@media screen and (max-width: 1280px) {
  .no-webp .casino-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/casino.e7a40316.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .casino-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/casino.954b4534.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .casino-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/casino.38c4e1c2.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .casino-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/casino.afd4f337.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

/* typography */

#mine {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  letter-spacing: 0.05rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: smaller;
}

/* layout */
#mine {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  flex: auto;
  min-height: 90vh;
  margin-top: 5rem;
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .mine-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/mine.f840660b.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .mine-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/mine.f3e1e77f.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .mine-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/mine.690b64e6.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .mine-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/mine.b086a8ac.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .mine-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/mine.89decebf.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .mine-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/mine.d240d2a5.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

/* typography */
.planet-container h3 {
  color: var(--clr-primary-100);
}
#ufo-enemy {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  letter-spacing: 0.05rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: smaller;
}

/* layout */
#ufo-enemy {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  flex: auto;
  margin-top: 5rem;
}

.ufo-wrapper {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* button */
#ufo-enemy button {
  color: var(--clr-primary-100);
  border: 1px solid var(--clr-primary-100);
  height: 1.5rem;
  font-size: 0.5rem;
}

#ufo-enemy button:hover {
  border: none;
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .ufo-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/ufo-2.32a68abe.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .ufo-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/ufo-2.8799e62c.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .ufo-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/ufo-2.faae30a2.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .ufo-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/ufo-2.01c2f4e9.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .ufo-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/ufo-2.9872a04d.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .ufo-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/ufo-2.e01e20c1.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

/* typography */
.planet-container h3 {
  color: var(--clr-primary-100);
}

#bar h4 {
  font-size: 1rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 0.3rem;
  color: yellow !important;
}

/* layout */

#bar-container {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: block;
  flex: auto;
}

#bar {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  flex: auto;
  margin-top: 5rem;
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  letter-spacing: 0.05rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: smaller;
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .bar-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/quiz.65c7de9b.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .bar-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/quiz.1eaec706.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .bar-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/quiz.9373bb5d.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .bar-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/quiz.4b2d979d.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .bar-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/quiz.6417a153.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .bar-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/quiz.860cdde9.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

/* carousel fix */

#bar .alice-carousel__dots-item {
  margin: 0.5rem;
}

/* typography */
.planet-container h3 {
  color: var(--clr-primary-100);
}
#temple {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  letter-spacing: 0.05rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: smaller;
}

/* layout */
#temple {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  flex: auto;
  display: flex;
  margin-top: 5rem;
}

.temple-wrapper {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: block;
  flex-direction: column;
}

/* background */

@media screen and (min-width: 1281px) {
  .no-webp .temple-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/temple.1ae638ed.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .temple-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/temple.96301a4d.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}
@media screen and (max-width: 1280px) {
  .no-webp .temple-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/temple.8045124a.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .temple-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/temple.a42422c5.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .temple-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/temple.2a2fd095.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .temple-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/temple.5b55fb51.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

/* layout */
#smuggler {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  flex: auto;
  display: flex;
  margin-top: 5rem;
}

#smuggler input {
  width: 220px;
  background-color: white !important;
  color: rgb(1, 9, 27) !important;
  display: inline-block;
  height: 1.5rem;
  font-size: 0.8rem;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 15px;
  border: none !important;
  margin: 0.5rem;
}

.smuggler-form {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

#smuggler p {
  margin-bottom: 1rem;
}

#smuggler .button-margin {
  margin: 0.5rem auto !important;
}

.margin-block-smuggler-container {
  margin: auto;
}
.margin-0-3rem {
  margin: 0.3rem !important;
}

.margin-bottom-2rem {
  margin-bottom: 2rem !important;
}
.inline-grid {
  display: inline-grid !important;
  display: -ms-inline-grid !important;
  display: -moz-inline-grid !important;
}

.vertical {
  display: flex;
  flex-direction: column;
}

/* background */

@media screen and (min-width: 1281px) {
  .no-webp .smuggler-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/tunnel.1fc7f0ee.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .smuggler-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/tunnel.d0b1720d.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}
@media screen and (max-width: 1280px) {
  .no-webp .smuggler-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/tunnel.e2ff47e4.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .smuggler-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/tunnel.c401c0e8.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .smuggler-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/tunnel.3b27b48b.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .smuggler-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/tunnel.a4fc0c9d.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (min-width: 1281px) {
  .no-webp .alchemist-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/vortex.51e55733.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .alchemist-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/vortex.0c50f818.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}
@media screen and (max-width: 1280px) {
  .no-webp .alchemist-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/vortex.2b040881.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .alchemist-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/vortex.00257a46.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .alchemist-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/vortex.49642c03.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .alchemist-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/vortex.3ed62dea.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

#gapTest input {
  max-width: 30px !important;
  background-color: transparent;
  color: #ffff;
  border: none;
  border-bottom: 1px solid #ffff;
}

#gapTest ul {
  margin-bottom: 1rem;
}

.modal-description {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

/* typography */
#sentenceTest {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  font-weight: normal;
  letter-spacing: 0.1rem;
  text-align: center;
}

#sentenceTest input {
  max-width: 50px !important;
  background-color: transparent !important;
  color: #ffff !important;
  border: none !important;
  border-bottom: 1px solid #ffff !important;
  border-radius: 0px !important;
  height: 15px;
}

.win {
  color: greenyellow;
}
.lose {
  color: red;
}

.modal-description {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.display-block {
  display: block;
}

/* button */

#sentenceTest button {
  color: var(--clr-primary-100);
  border: 1px solid var(--clr-primary-100);
  margin-top: 1rem;
  height: 1.5rem;
  font-size: 0.5rem;
}

#sentenceTest button:hover {
  border: none;
}

/* typography */
.planet-container h3 {
  color: var(--clr-primary-100);
}
#testMenu {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  letter-spacing: 0.05rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: smaller;
}

#testMenu input {
  max-width: 50px !important;
  background-color: transparent !important;
  color: #ffff !important;
  border: none !important;
  border-bottom: 1px solid #ffff !important;
  border-radius: 0px !important;
  height: 15px;
}

/* layout */
#testMenu {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  flex: auto;
  min-height: 90vh;
  margin-top: 5rem;
}

#testMenu li {
  border-left: 0 !important;
  margin: 0.2rem !important;
  display: inline-block !important;
  letter-spacing: 0.05rem !important;
}

.testMenu-wrapper {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: block;
  flex-direction: column;
}

@media screen and (min-width: 760px) {
  .testMenu-activities-container {
    width: min(90%, 70rem);
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

.testMenu-split {
  /* display: flex; */
  flex-direction: row;
  align-items: center;
}
.test-buttons {
  display: inline-grid;
}

.test-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1020px) {
  .testMenu-split {
    margin-bottom: 5rem;
  }
}

@media (min-width: 480px) {
  .testMenu-split {
    flex-direction: column;
  }
  .testMenu-split > * {
    flex-basis: 100%;
  }
}

#testMenu .button-margin {
  margin: 0.5rem auto !important;
}

.margin-block-testMenu-container {
  margin: auto;
}
.margin-0-3rem {
  margin: 0.3rem !important;
}

.margin-bottom-2rem {
  margin-bottom: 2rem !important;
}
.hidden {
  display: none;
}

/* button */
#testMenu button {
  color: var(--clr-primary-100);
  border: 1px solid var(--clr-primary-100);
}

#testMenu button:hover {
  border: none;
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .testMenu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/university.58680f95.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .testMenu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/university.7a687b38.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .testMenu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/university.677e3e44.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .testMenu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/university.abe1ac05.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .testMenu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/university.2c83aff3.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .testMenu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/university.a0c05f3b.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

img {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.trophy img {
  width: 25% !important;
}

@media screen and (min-width: 1920px) {
  .trophy img {
    width: 20% !important;
  }
}
.trophy {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.trophy:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

/* typography */
.trophies-container h3 {
  color: yellow !important;
}

#trophies {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  font-weight: normal;
  letter-spacing: 0.1rem;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: smaller;
}

/* layout */

#trophies {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: flex;
  flex: auto;
  margin-top: 5rem;
}
.trophies-wrapper {
  align-items: center;
  justify-content: center;
  align-content: center;
}

.trophies-container {
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: min(80%, 70rem);
  padding-bottom: auto;
  text-align: center;
}
.trophies-container article article {
  text-align: center;
}
.margin-block-trophies-container {
  margin: auto;
}

.trophies-activities-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
}

@media screen and (min-width: 1024px) {
  .trophies-activities-container {
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 960px) {
  .trophies-activities-container {
    margin-top: 2rem;
    width: min(90%, 70rem);
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

/* button */
#trophies button {
  color: var(--clr-primary-100);
  border: 1px solid var(--clr-primary-100);
  margin-top: 1rem;
  height: 1.5rem;
  font-size: 0.5rem;
}

#trophies button:hover {
  border: none;
}

.fav-del-button {
  margin: 0.5rem;
}

.fav-title {
  display: inline-flex;
  align-items: center;
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .trophies-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/sky.08994b8a.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .trophies-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/sky.02677a6b.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .trophies-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/sky.d175778e.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .trophies-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/sky.094c5f89.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .trophies-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/sky.e33c2854.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .trophies-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/sky.0a2b0d8a.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

#preferences {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: flex;
  flex: auto;
  margin-top: 5rem;
}
#preferences input {
  width: 100%;
  background-color: white !important;
  color: var(--clr-primary-800) !important;
  display: inline-block;
  height: 1.5rem;
  font-size: 0.8rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 15px;
  border: none !important;
  height: 35px;
  padding: 0.5rem 0;
  border-radius: 16px;
  margin: 0.5rem 0 !important;
}

.preferences-wrapper {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: block;
  text-align: center;
  min-height: 90vh;
}

.cursor-pointer {
  cursor: pointer;
}

.preferences-container {
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: min(80%, 70rem);
  padding-bottom: auto;
  text-align: center;
}
.preferences-form-container {
  letter-spacing: 0.5px;
  border-radius: 25px 0;
  z-index: 2;
  margin-top: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preferences-form {
  display: flex;
  flex-direction: column;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  min-width: 280px;
}

.container-split {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

@media screen and (min-width: 1020px) {
  .container-split {
    margin-bottom: 5rem;
  }
}

@media (min-width: 480px) {
  .container-split {
    flex-direction: row;
  }
  .container-split > * {
    flex-basis: 100%;
  }
}

.margin-05-0 {
  margin: 0.5rem 0 !important;
}

.margin-top-1rem {
  margin-top: 1rem !important;
}

.margin-top-2rem {
  margin-top: 2rem !important;
}

.preferences-container article article {
  text-align: center;
}

.margin-block-preferences-container {
  margin: 2rem auto;
}

.preferences-description {
  text-align: centter;
  padding: 1rem;
  width: 320px;
  margin-bottom: 0;
}

.back-button {
  margin: 1rem 0 0 0;
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .preferences-activities-container {
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 960px) {
  .preferences-activities-container {
    margin-top: 2rem;
    width: min(90%, 70rem);
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .preferences-form-container {
    min-width: 300px;
  }
}

.warning {
  background-color: var(--clr-warning) !important;
  color: white !important;
}

.warning:hover {
  background-color: white !important;
  color: black !important;
}

.caution {
  background-color: var(--clr-yellow) !important;
}

.caution:hover {
  background-color: white !important;
}

.red {
  background-color: var(--clr-warning) !important;
}

.red:hover {
  background-color: white !important;
}

.green {
  background-color: var(--clr-green) !important;
}

.green:hover {
  background-color: white !important;
}

.line {
  height: 1px;
  width: 80%;
  border-bottom: 1px solid gray;
}

/* button */
#preferences button {
  color: var(--clr-primary-100);
  border: 1px solid var(--clr-primary-100);
  height: 1.5rem;
  font-size: 0.5rem;
}

#preferences button:hover {
  border: none;
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .preferences-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/menu.a515f1e5.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .preferences-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/menu.874df8db.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .preferences-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/menu.f0f8f029.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .preferences-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/menu.6d44f731.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .preferences-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/menu.8f43178e.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .preferences-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/menu.735e69af.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

.margin-right-03 {
  margin-right: 0.2rem;
  font-size: 0.8rem;
}

.visibility-toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#auth input {
  width: 100%;
  background-color: white !important;
  color: var(--clr-primary-800) !important;
  display: inline-block;
  height: 1.5rem;
  font-size: 0.8rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 15px;
  border: none !important;
  height: 35px !important;
  padding: 0.5rem 0;
  border-radius: 16px;
  margin: 0.5rem 0 !important;
}

.auth-container {
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: min(80%, 70rem);
  padding-bottom: auto;
  text-align: center;
}
.auth-form-container {
  letter-spacing: 0.5px;
  border-radius: 25px 0;
  z-index: 2;
  margin-top: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-form {
  display: flex;
  flex-direction: column;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  min-width: 280px;
}

.auth-description {
  text-align: centter;
  padding: 1rem;
  width: 320px;
  margin-bottom: 0;
}

/* button */
#auth button {
  color: var(--clr-primary-100);
  border: 1px solid var(--clr-primary-100);
  width: 100%;
  margin: 0.5rem 0 !important;
  height: 35px !important;
  padding: 0.5rem 0;
  border-radius: 16px;
  font-size: 0.6rem !important;
}

#google-button {
  margin: 0.5rem 0 1rem 0;
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .auth-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/forest.d75ffc9f.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .auth-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/forest.693409c2.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .auth-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/forest.d9dfb01e.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .auth-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/forest.ae5f7c60.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .auth-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/forest.1d6a515e.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .auth-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/forest.c73dd042.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

.auth-wrapper {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.auth-container {
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: min(80%, 70rem);
  padding-bottom: auto;
  text-align: center;
}

.auth-description {
  text-align: centter;
  padding: 1rem;
  width: 320px;
  margin-bottom: 0;
}

#google-button {
  margin: 0.5rem 0 1rem 0;
}
.background {
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 1) 100%
    ),
    url(/static/media/forest.d75ffc9f.jpg);
  background-size: cover;
  overflow: scroll;
  min-height: 100vh;
  background-repeat: no-repeat;
  margin-top: -5rem;
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .auth-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/forest.d75ffc9f.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .auth-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/forest.693409c2.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .auth-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/forest.d9dfb01e.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .auth-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/forest.ae5f7c60.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .auth-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/forest.1d6a515e.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .auth-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/forest.c73dd042.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (min-width: 960px) {
  .content-help {
    width: 80%;
    margin-left: 5rem;
    margin-right: 3rem;
  }
  .underline-places-help-left {
    width: 60%;
    height: 0.1rem;
    margin-right: -2rem;
    background-color: white;
    align-self: center;
    margin-left: 1rem;
  }
  .underline-places-help-right {
    width: 60%;
    height: 0.1rem;
    margin-left: -2rem;
    background-color: white;
    align-self: center;
    margin-right: 1rem;
  }
  .places-header-help {
    display: flex;
    width: min(40%, 70rem);
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
}

.padding-1rem {
  padding: 1rem;
}

.desktop {
  display: none;
}

.place-description-help {
  margin-bottom: 0 !important;
  letter-spacing: 0.06rem;
  margin-right: auto;
  justify-content: center;
  align-content: center;
  align-self: center;
}

@media screen and (min-width: 480px) {
  .place-container-help {
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: flex-start;
    border-radius: 10px;
  }
  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
}

.place-split-help {
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
}

@media screen and (min-width: 1020px) {
  .place-split-help {
    margin-bottom: 5rem;
  }
}

@media (min-width: 480px) {
  .place-split-help {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .place-split-help > * {
    flex-basis: 100%;
  }
}

@media screen and (min-width: 480px) {
  .underline-places-help-left,
  .underline-places-help-right {
    width: 70%;
  }
}

@media screen and (min-width: 960px) {
  .underline-places-help-left {
    width: 60%;
    height: 0.1rem;
    margin-right: -2rem;
    background-color: white;
    align-self: center;
    margin-left: 1rem;
  }
  .underline-places-help-right {
    width: 60%;
    height: 0.1rem;
    margin-left: -2rem;
    background-color: white;
    align-self: center;
    margin-right: 1rem;
  }
  .places-header-help {
    display: flex;
    width: min(40%, 70rem);
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  .place-container-help h3 {
    justify-self: center;
  }
}

@media screen and (max-width: 960px) {
  .place-description-help {
    border-left: none;
  }
}

.place-description-help {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* typography */
.place-container-help h3 {
  color: var(--clr-primary-100);
}
#help {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  letter-spacing: 0.05rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: smaller;
}

#help h2 {
  font-size: 1.5rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 0.6rem;
}

@media screen and (min-width: 960px) {
  #help h2 {
    font-size: 2rem;
  }
}
@media screen and (min-width: 960px) {
  .content-help {
    width: 80%;
    margin-left: 5rem;
    margin-right: 3rem;
  }
}

#help h3 {
  font-size: 1rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: yellow;
  letter-spacing: 0.3rem;
}
#help h4 {
  font-size: 1rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: yellow;
  letter-spacing: 0.3rem;
}
.place-info-container-help h4 {
  color: yellow !important;
}

/* layout */
#help {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  min-height: 90vh;
  margin-top: 5rem;
}

#help section {
  margin-bottom: 3rem;
}
.flex-auto {
  flex: auto;
}
@media screen and (min-width: 1024px) {
  .place-activities-container-help {
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 960px) {
  .place-activities-container-help {
    margin-top: 2rem;
    width: min(70%, 70rem);
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .place-info-container-help {
    bottom: 2rem;
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }
  .map-header-help {
    margin-bottom: 5rem;
    width: min(40%, 70rem);
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
  }
}
.flex-centered {
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  justify-content: center;
  justify-items: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.place-content-container-help {
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: min(100%, 70rem);
  padding-bottom: auto;
  text-align: center;
}
.header-container-help {
  margin-bottom: 3rem;
}
.underline-help {
  display: none;
}
.underline-places-help-left {
  width: 60%;
  height: 0.1rem;
  margin-right: -2rem;
  background-color: white;
  align-self: center;
  margin-left: 1rem;
}
.underline-places-help-right {
  width: 60%;
  height: 0.1rem;
  margin-left: -2rem;
  background-color: white;
  align-self: center;
  margin-right: 1rem;
}
.places-header-help {
  display: flex;
  width: min(40%, 70rem);
  margin-inline: auto;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.place-container-help {
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: min(80%, 70rem);
  padding-bottom: auto;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.place-container-help article article {
  text-align: center;
}
.desktop {
  display: none;
}
.padding-1rem {
  padding: 1rem;
}

.place-description-help {
  margin-bottom: 0 !important;
  letter-spacing: 0.06rem;
  margin-right: auto;
  justify-content: center;
  align-content: center;
  align-self: center;
}

@media screen and (min-width: 480px) {
  .place-container-help {
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: flex-start;
    border-radius: 10px;
  }

  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
}

@media screen and (min-width: 960px) {
  .underline-help {
    display: block;
    width: 65%;
    height: 0.1rem;
    margin-left: -2rem;
    background-color: white;
    margin-bottom: 25px;
  }
}

.place-split-help {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 1020px) {
  .place-split-help {
    margin-bottom: 5rem;
  }
}

@media (min-width: 480px) {
  .place-split-help {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .place-split-help > * {
    flex-basis: 100%;
  }
}

@media screen and (min-width: 480px) {
  .underline-help {
    width: 70%;
    margin-bottom: 25px;
  }
  .underline-places-help-left,
  .underline-places-help-right {
    width: 70%;
  }
}

@media screen and (min-width: 960px) {
  #help h2 {
    align-self: flex-start;
    justify-self: left;
    margin-right: -100px;
  }
  .underline-help {
    width: 65%;
    height: 0.1rem;
    margin-left: -2rem;
    background-color: white;
    margin-bottom: 25px;
  }
  .underline-places-help-left {
    width: 60%;
    height: 0.1rem;
    margin-right: -2rem;
    background-color: white;
    align-self: center;
    margin-left: 1rem;
  }
  .underline-places-help-right {
    width: 60%;
    height: 0.1rem;
    margin-left: -2rem;
    background-color: white;
    align-self: center;
    margin-right: 1rem;
  }
  .places-header-help {
    display: flex;
    width: min(40%, 70rem);
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  .place-container-help h3 {
    justify-self: center;
  }

  .sidebar-help {
    display: flex;
    padding: 1rem !important;
    margin-bottom: 0 !important;
    letter-spacing: 0.06rem;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    justify-content: center;
    align-content: right;
    align-self: center;
  }
}
@media screen and (max-width: 960px) {
  .sidebar-help {
    flex-direction: row;
    border-right: none;
    text-align: center;
  }
}

@media screen and (max-width: 960px) {
  .place-description-help {
    border-left: none;
  }
}

.inline-block {
  display: inline-block;
}

.place-description-help {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 960px) {
  .border {
    border-bottom: none;
  }
}

.not-found p {
  margin-bottom: 0;
}

.border-radius {
  border-radius: 10px;
}

.padding-places {
  padding: 1rem;
  justify-content: flex-start;
}

.padding-botton-3rem {
  padding-bottom: 3rem;
}

.padding-1rem {
  padding: 1rem;
}

.padding-3rem {
  padding: 3rem;
}

.margin-1rem {
  margin: 1rem;
}

#help .button-margin {
  margin: 0.5rem auto !important;
}

.margin-block-place-container {
  margin: auto;
}

#help img {
  width: 55% !important;
}
@media screen and (min-width: 960px) {
  #help img {
    width: 30% !important;
  }
}

@media screen and (min-width: 420px) {
  #help img {
    width: 40% !important;
  }
}
@media screen and (min-width: 1024px) {
  #help img {
    width: 40% !important;
  }
}

@media screen and (min-width: 1280px) {
  #help img {
    width: 25% !important;
  }
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .help-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/fireball.a1b61de1.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .help-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/fireball.32c0a827.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .help-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/fireball.9fcea539.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .help-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/fireball.730073e7.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .help-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/fireball.9fcea539.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .help-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/static/media/fireball.a938ce64.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

