/* typography */
.planet-container h3 {
  color: var(--clr-primary-100);
}
#testMenu {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  letter-spacing: 0.05rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: smaller;
}

#testMenu input {
  max-width: 50px !important;
  background-color: transparent !important;
  color: #ffff !important;
  border: none !important;
  border-bottom: 1px solid #ffff !important;
  border-radius: 0px !important;
  height: 15px;
}

/* layout */
#testMenu {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  flex: auto;
  min-height: 90vh;
  margin-top: 5rem;
}

#testMenu li {
  border-left: 0 !important;
  margin: 0.2rem !important;
  display: inline-block !important;
  letter-spacing: 0.05rem !important;
}

.testMenu-wrapper {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: block;
  flex-direction: column;
}

@media screen and (min-width: 760px) {
  .testMenu-activities-container {
    width: min(90%, 70rem);
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

.testMenu-split {
  /* display: flex; */
  flex-direction: row;
  align-items: center;
}
.test-buttons {
  display: inline-grid;
}

.test-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1020px) {
  .testMenu-split {
    margin-bottom: 5rem;
  }
}

@media (min-width: 480px) {
  .testMenu-split {
    flex-direction: column;
  }
  .testMenu-split > * {
    flex-basis: 100%;
  }
}

#testMenu .button-margin {
  margin: 0.5rem auto !important;
}

.margin-block-testMenu-container {
  margin: auto;
}
.margin-0-3rem {
  margin: 0.3rem !important;
}

.margin-bottom-2rem {
  margin-bottom: 2rem !important;
}
.hidden {
  display: none;
}

/* button */
#testMenu button {
  color: var(--clr-primary-100);
  border: 1px solid var(--clr-primary-100);
}

#testMenu button:hover {
  border: none;
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .testMenu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/university.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .testMenu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/university.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .testMenu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/university.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .testMenu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/university.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .testMenu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/university.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .testMenu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/university.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}
