/* typography */
.planet-container h3 {
  color: var(--clr-primary-100);
}
#planet {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  letter-spacing: 0.05rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: smaller;
}

#planet h2 {
  font-size: 2rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 0.6rem;
}

#planet h3 {
  font-size: 1rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: yellow;
  letter-spacing: 0.3rem;
}
#planet h4 {
  font-size: 1rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 0.3rem;
}
.planet-info-container h4 {
  color: yellow !important;
}

.color-win {
  color: greenyellow;
}
.color-lose {
  color: red;
}

.visited {
  color: var(--clr-neutral-300);
}

/* layout */
#planet {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  min-height: 90vh;
  margin-top: 5rem;
}
.flex-auto {
  flex: auto;
}

@media screen and (min-width: 960px) {
  .planet-stats-first {
    margin-left: 5rem;
    margin-right: 1rem;
  }
  .planet-stats-middle {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .planet-stats-last {
    margin-left: 1rem;
    margin-right: 5rem;
  }
}

@media screen and (min-width: 1024px) {
  .planet-activities-container {
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 960px) {
  .planet-activities-container {
    width: min(80%, 70rem);
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .planet-info-container {
    bottom: 2rem;
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }
  .map-header {
    margin-bottom: 5rem;
    width: min(40%, 70rem);
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
  }
}
.flex-centered {
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  justify-content: center;
  justify-items: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.planet-content-container {
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: min(100%, 70rem);
  padding-bottom: auto;
  text-align: center;
}

.header-container {
  margin-bottom: 3rem;
}

.planet-container {
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding-bottom: auto;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.planet-container article article {
  text-align: center;
}
.desktop {
  display: none;
}

@media screen and (min-width: 960px) {
  .planet-container {
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: flex-start;
    border-radius: 10px;
  }
  .mobile {
    display: none;
  }
  .desktop {
    display: contents;
  }
}
.width-80 {
  width: min(95%, 70rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.planet-split {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 1020px) {
  .planet-split {
    margin-bottom: 5rem;
  }
}

.planet-split {
  margin-bottom: 3rem;
}

@media (min-width: 960px) {
  .planet-split {
    flex-direction: row;
  }
  .planet-split > * {
    flex-basis: 100%;
  }
}
@media screen and (min-width: 480px) {
  .width-80 {
    width: min(80%, 70rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 960px) {
  #planet h2 {
    align-self: flex-start;
    justify-self: left;
    margin-right: -100px;
  }
}

@media screen and (min-width: 960px) {
  .border {
    border-bottom: none;
  }
}

.not-found p {
  margin-bottom: 0;
}

.border-radius {
  border-radius: 10px;
}

.padding-places {
  padding: 1rem;
}

.padding-botton-3rem {
  padding-bottom: 3rem;
}

.padding-1rem {
  padding: 1rem;
}

.padding-3rem {
  padding: 3rem;
}

.margin-1rem {
  margin: 1rem;
}

.margin-block-planet-container {
  margin: auto;
}

/* carousel customization */

#mercenaries-list .alice-carousel__wrapper {
  height: 450px !important;
}

#mercenaries .alice-carousel__wrapper {
  height: 450px !important;
}

.alice-carousel__wrapper {
  height: 350px !important;
}

@media screen and (min-width: 1024px) {
  .alice-carousel__wrapper {
    height: 370px !important;
  }
}

@media screen and (min-width: 2200px) {
  .alice-carousel__wrapper {
    height: 450px !important;
  }
}

@media screen and (min-width: 1020px) and (max-height: 780px) {
  .alice-carousel__wrapper {
    height: 320px !important;
  }
}

@media screen and (max-width: 960px) {
  .alice-carousel__wrapper {
    height: 300px !important;
  }
}

.carousel-card {
  margin: 0.5rem;
  border-radius: 15px;
}

.alice-carousel {
  padding-bottom: 2rem;
  color: inherit;
}

.alice-carousel__stage-item {
  display: inline-flex !important;
  justify-content: center !important;
}

.alice-carousel__next-btn {
  text-align: left;
}

.alice-carousel__prev-btn {
  text-align: right;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  color: white !important;
}

.alice-carousel__prev-btn [data-area]::after {
  content: "< prev";
  font-size: 1rem;
  color: white;
}

.alice-carousel__next-btn [data-area]::after {
  content: "next >";
  font-size: 1rem;
  color: white;
}

@media screen and (max-width: 1024px) {
  .carousel-card {
    background-color: transparent;
  }
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  color: white;
  font-weight: 500;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.carousel-card {
  transition: transform 0.2s;
}

.carousel-card:hover {
  transform: scale(1.05);
}

/* background images */

@media screen and (min-width: 1281px) {
  .no-webp .crion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/crion.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .crion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/crion.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .therion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/therion.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .therion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/therion.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .crystalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/crystalia.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .crystalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/crystalia.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .desertia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/desertia.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .desertia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/desertia.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .xillon-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/xillon.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .xillon-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/xillon.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .axios-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/axios.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .axios-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/axios.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .thalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/thalia.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .thalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/thalia.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .bathea-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/bathea.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .bathea-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/bathea.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .centuria-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/centuria.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .centuria-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/centuria.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .menu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/menu.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .menu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/menu.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .ufo-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/ufo.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .ufo-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/ufo.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .crion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/crion.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .crion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/crion.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .therion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/therion.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .therion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/therion.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .crystalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/crystalia.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .crystalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/crystalia.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .desertia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/desertia.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .desertia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/desertia.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .xillon-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/xillon.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .xillon-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/xillon.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .axios-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/axios.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .axios-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/axios.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .thalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/thalia.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .thalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/thalia.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .bathea-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/bathea.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .bathea-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/bathea.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .centuria-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/centuria.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .centuria-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/centuria.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .menu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/menu.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .menu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/menu.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .ufo-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/ufo.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .ufo-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/ufo.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .crion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/crion.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .crion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/crion.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .therion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/therion.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .therion-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/therion.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .crystalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/crystalia.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .crystalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/crystalia.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .desertia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/desertia.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .desertia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/desertia.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .xillon-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/xillon.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .xillon-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/xillon.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .axios-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/axios.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .axios-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/axios.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .thalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/thalia.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .thalia-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/thalia.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .bathea-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/bathea.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .bathea-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/bathea.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .centuria-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/centuria.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .centuria-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/centuria.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .menu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/menu.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .menu-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/menu.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }

  .no-webp .ufo-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/ufo.jpg);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .ufo-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/ufo.webp);
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}
