#footer {
  padding: 1rem;
  background: transparent;
  margin-top: -5rem;
  z-index: 99999;
}

#footer > h3 {
  color: var(--clr-neutral-100) !important;
  letter-spacing: 0.3rem;
}
