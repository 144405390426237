.thumbnail img {
  width: 50% !important;
  border-radius: 15px;
}

@media screen and (max-width: 480px) {
  .thumbnail img {
    width: 90% !important;
  }
}
