img {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.logo img {
  width: 25% !important;
}

@media screen and (min-width: 1920px) {
  .logo img {
    width: 20% !important;
  }
}
