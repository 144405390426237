#preferences {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: flex;
  flex: auto;
  margin-top: 5rem;
}
#preferences input {
  width: 100%;
  background-color: white !important;
  color: var(--clr-primary-800) !important;
  display: inline-block;
  height: 1.5rem;
  font-size: 0.8rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 15px;
  border: none !important;
  height: 35px;
  padding: 0.5rem 0;
  border-radius: 16px;
  margin: 0.5rem 0 !important;
}

.preferences-wrapper {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: block;
  text-align: center;
  min-height: 90vh;
}

.cursor-pointer {
  cursor: pointer;
}

.preferences-container {
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: min(80%, 70rem);
  padding-bottom: auto;
  text-align: center;
}
.preferences-form-container {
  letter-spacing: 0.5px;
  border-radius: 25px 0;
  z-index: 2;
  margin-top: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preferences-form {
  display: flex;
  flex-direction: column;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  min-width: 280px;
}

.container-split {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

@media screen and (min-width: 1020px) {
  .container-split {
    margin-bottom: 5rem;
  }
}

@media (min-width: 480px) {
  .container-split {
    flex-direction: row;
  }
  .container-split > * {
    flex-basis: 100%;
  }
}

.margin-05-0 {
  margin: 0.5rem 0 !important;
}

.margin-top-1rem {
  margin-top: 1rem !important;
}

.margin-top-2rem {
  margin-top: 2rem !important;
}

.preferences-container article article {
  text-align: center;
}

.margin-block-preferences-container {
  margin: 2rem auto;
}

.preferences-description {
  text-align: centter;
  padding: 1rem;
  width: 320px;
  margin-bottom: 0;
}

.back-button {
  margin: 1rem 0 0 0;
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .preferences-activities-container {
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 960px) {
  .preferences-activities-container {
    margin-top: 2rem;
    width: min(90%, 70rem);
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .preferences-form-container {
    min-width: 300px;
  }
}

.warning {
  background-color: var(--clr-warning) !important;
  color: white !important;
}

.warning:hover {
  background-color: white !important;
  color: black !important;
}

.caution {
  background-color: var(--clr-yellow) !important;
}

.caution:hover {
  background-color: white !important;
}

.red {
  background-color: var(--clr-warning) !important;
}

.red:hover {
  background-color: white !important;
}

.green {
  background-color: var(--clr-green) !important;
}

.green:hover {
  background-color: white !important;
}

.line {
  height: 1px;
  width: 80%;
  border-bottom: 1px solid gray;
}

/* button */
#preferences button {
  color: var(--clr-primary-100);
  border: 1px solid var(--clr-primary-100);
  height: 1.5rem;
  font-size: 0.5rem;
}

#preferences button:hover {
  border: none;
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .preferences-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/menu.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .preferences-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/menu.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .preferences-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/menu.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .preferences-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/menu.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .preferences-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/menu.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .preferences-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/menu.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}
