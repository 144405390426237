/* typography */
.welcome-container h3 {
  color: var(--clr-primary-100);
}
#welcome {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  letter-spacing: 0.05rem;
  text-align: center;
  font-size: smaller;
}

#welcome h2 {
  font-size: 2rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 0.6rem;
}

#welcome h3 {
  font-size: 1rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: yellow;
  letter-spacing: 0.3rem;
}
#welcome h4 {
  font-size: 1rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 0.3rem;
}
.welcome-info-container h4 {
  color: yellow !important;
}

/* layout */

#intro {
  padding: 0 1rem 0 1rem;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -moz-justify-content: -moz-flex-end;
  -webkit-justify-content: -webkit-flex-end;
  -ms-justify-content: -ms-flex-end;
  justify-content: flex-end;
  position: relative;
  cursor: default;
  text-align: center;
  min-height: 100vh;
  margin-top: 5rem;
}

#welcome {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-bottom: 3rem;
}

@media (min-width: 1025px) {
  .intro-split {
    display: flex;
    flex-direction: row !important;
  }
}

@media (max-width: 1024px) {
  .menu-title h2 {
    font-size: 1.5rem !important;
    font-weight: 200;
    font-family: var(--ff-primary);
    text-transform: uppercase;
    letter-spacing: 0.6rem;
  }

  .menu-title .underline {
    width: 60%;
  }
}

@media (min-width: 480px) {
  .intro-split > * {
    flex-basis: 100%;
  }
}

@media (min-width: 1025px) {
  #welcome .planet-split {
    flex-direction: row !important;
  }
}

.shadow-bg {
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.4);
}

.gallery-split {
  display: flex;
  flex-direction: column;
}

@media (min-width: 480px) {
  .gallery-split {
    flex-direction: row !important;
  }
}

@media (min-width: 480px) {
  #welcome .planet-split > * {
    flex-basis: 100%;
  }
}

@media screen and (max-width: 1024px) {
  #intro article {
    margin-bottom: 5rem;
  }
}

@media screen and (max-width: 1024px) {
  #welcome .underline-places {
    width: 30% !important;
    justify-self: center;
    align-self: center;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 640px) {
  #welcome .underline-places {
    width: 60% !important;
  }
}

#welcome .underline-places {
  width: 60%;
}

#welcome .places-header {
  width: 100%;
}

#welcome .header-container {
  padding-top: 1rem !important;
  padding-bottom: 3rem !important;
}

@media (min-width: 1025px) {
  #welcome .header-container {
    padding-top: 1rem !important;
    padding-bottom: 5rem !important;
  }
}

.menu-navbar {
  width: 100%;
}

.menu-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media screen and (min-width: 960px) {
  .content {
    width: 80%;
    margin-left: 5rem;
    margin-right: 3rem;
  }
}

/* general */

#welcome > h2 {
  padding-bottom: 2rem;
}

#welcome p {
  margin: 1rem;
}

#welcome > a {
  color: inherit;
}

#welcome > form {
  padding: 1rem;
}

#welcome {
  color: var(--clr-neutral-100);
  margin-block: auto;
}
#welcome .border {
  border: 1px solid #e1f5fce1 !important;
}

#welcome .welcome-form {
  align-content: center;
  justify-content: center;
  display: grid;
}

.menu-nav {
  display: inline-flex;
  padding-bottom: 1.5rem;
}

.no-decoration {
  text-decoration: none;
}

@media screen and (min-width: 1025px) {
  #welcome .places-header {
    width: 100%;
    flex-direction: column !important;
    margin-bottom: 1rem;
  }
}

/* arrow link*/

.nav-arrow {
  display: inline-block;
}

.nav-arrow:hover {
  color: yellow;
}

@media screen and (max-width: 480px) {
  .menu-nav {
    flex-direction: column;
    padding-top: 1.5rem;
  }
}

@media screen and (max-width: 960px) {
  .planet-description {
    border-left: none;
  }
}
.width-80 {
  width: min(80%, 70rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.inline-block {
  display: inline-block;
}

.planet-description {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 960px) {
  .border {
    border-bottom: none;
  }
}

.rotate180deg {
  transform: rotate(180deg);
}

.not-found p {
  margin-bottom: 0;
}

.border-radius {
  border-radius: 10px;
}

.padding-places {
  padding: 1rem;
}

.padding-botton-3rem {
  padding-bottom: 3rem;
}

.padding-1rem {
  padding: 1rem;
}

.padding-3rem {
  padding: 3rem;
}

.padding-2rem {
  padding: 2rem;
}

.margin-1rem {
  margin: 1rem;
}

#welcome .button-margin {
  margin: 0.5rem 0.5rem !important;
}
#welcome .button {
  margin: 0.5rem 1rem;
  font-size: 0.6rem !important;
}

#welcome .colors-inverted {
  background-color: yellow !important;
}

#welcome .colors-inverted:hover {
  background-color: white !important;
}

.margin-block-planet-container {
  margin: auto;
}

#modal .bg-green {
  background-color: rgb(125, 215, 120) !important;
}
#modal .bg-green:hover {
  background-color: white !important;
}

#modal .bg-red {
  background-color: rgb(235, 110, 105) !important;
}
#modal .bg-red:hover {
  background-color: white !important;
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .welcome-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url("/src/images/planet-images/1920/planet.webp") no-repeat;
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .welcome-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url("/src/images/planet-images/1920/planet.webp") no-repeat;
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}
@media screen and (max-width: 1280px) {
  .no-webp .welcome-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url("/src/images/planet-images/1280/planet.webp") no-repeat;
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .welcome-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url("/src/images/planet-images/1280/planet.webp") no-repeat;
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .welcome-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url("/src/images/planet-images/960/planet.webp") no-repeat;
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .welcome-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url("/src/images/planet-images/960/planet.webp") no-repeat;
    overflow: scroll;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}
