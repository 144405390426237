/* typography */
.trophies-container h3 {
  color: yellow !important;
}

#trophies {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  font-weight: normal;
  letter-spacing: 0.1rem;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: smaller;
}

/* layout */

#trophies {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: flex;
  flex: auto;
  margin-top: 5rem;
}
.trophies-wrapper {
  align-items: center;
  justify-content: center;
  align-content: center;
}

.trophies-container {
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: min(80%, 70rem);
  padding-bottom: auto;
  text-align: center;
}
.trophies-container article article {
  text-align: center;
}
.margin-block-trophies-container {
  margin: auto;
}

.trophies-activities-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
}

@media screen and (min-width: 1024px) {
  .trophies-activities-container {
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 960px) {
  .trophies-activities-container {
    margin-top: 2rem;
    width: min(90%, 70rem);
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

/* button */
#trophies button {
  color: var(--clr-primary-100);
  border: 1px solid var(--clr-primary-100);
  margin-top: 1rem;
  height: 1.5rem;
  font-size: 0.5rem;
}

#trophies button:hover {
  border: none;
}

.fav-del-button {
  margin: 0.5rem;
}

.fav-title {
  display: inline-flex;
  align-items: center;
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .trophies-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/sky.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .trophies-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/sky.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .trophies-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/sky.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .trophies-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/sky.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .trophies-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/sky.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .trophies-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/sky.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}
