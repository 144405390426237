/* typography */
#sentenceTest {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  font-weight: normal;
  letter-spacing: 0.1rem;
  text-align: center;
}

#sentenceTest input {
  max-width: 50px !important;
  background-color: transparent !important;
  color: #ffff !important;
  border: none !important;
  border-bottom: 1px solid #ffff !important;
  border-radius: 0px !important;
  height: 15px;
}

.win {
  color: greenyellow;
}
.lose {
  color: red;
}

.modal-description {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.display-block {
  display: block;
}

/* button */

#sentenceTest button {
  color: var(--clr-primary-100);
  border: 1px solid var(--clr-primary-100);
  margin-top: 1rem;
  height: 1.5rem;
  font-size: 0.5rem;
}

#sentenceTest button:hover {
  border: none;
}
