#gapTest input {
  max-width: 30px !important;
  background-color: transparent;
  color: #ffff;
  border: none;
  border-bottom: 1px solid #ffff;
}

#gapTest ul {
  margin-bottom: 1rem;
}

.modal-description {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
