/* typography */
.planet-container h3 {
  color: var(--clr-primary-100);
}
#quiz {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  letter-spacing: 0.05rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: smaller;
}

/* layout */
#quiz {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  flex: auto;
  display: flex;
  min-height: 90vh;
  margin-top: 5rem;
}

.quiz-wrapper {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#quiz form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#quiz ul,
li {
  border-left: 0;
  padding-left: 0;
  display: block;
  list-style: none;
}

.padding-05rem {
  padding: 0.5rem;
}

@media screen and (min-width: 960px) {
  .content {
    width: 80%;
    margin-left: 5rem;
    margin-right: 3rem;
  }
}
.quiz-container {
  display: flex;
  height: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.flex-column {
  flex-direction: column;
}

.quiz-split {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 1020px) {
  .quiz-split {
    margin-bottom: 5rem;
  }
}

@media (min-width: 480px) {
  .quiz-split {
    flex-direction: row;
  }
  .quiz-split > * {
    flex-basis: 100%;
  }
}
@media screen and (min-width: 480px) {
  .align-self-flex-start {
    align-self: flex-start;
  }
  .margin-bottom-0 {
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 960px) {
  #quiz h2 {
    align-self: flex-start;
    justify-self: left;
    margin-right: -100px;
  }
}

@media screen and (min-width: 960px) {
  .border {
    border-bottom: none;
  }
}

#quiz p {
  margin-bottom: 1rem;
}

.not-found p {
  margin-bottom: 0;
}

#quiz .button-margin {
  margin: 0.5rem auto !important;
}

.margin-block-quiz-container {
  margin: auto;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
