.definition-search-info-container h4 {
  color: yellow !important;
}

@media screen and (min-width: 960px) {
  .content {
    width: 80%;
    margin-left: 5rem;
    margin-right: 3rem;
  }
}

#definition-search .button-margin {
  margin: 0.5rem auto !important;
}

.logo img {
  width: 35% !important;
}

@media screen and (min-width: 420px) {
  .logo img {
    width: 30% !important;
  }
}
@media screen and (max-width: 420px) {
  .logo img {
    width: 30% !important;
  }
}
