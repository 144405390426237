#questions .dialogue-line {
  border-left: 0;
  margin: 1rem 0 1rem 0 !important;
  padding-left: 0;
  display: block !important;
  list-style: none;
  white-space: pre-line;
}

#answers .dialogue-line {
  border-left: 0;
  margin: 1rem 0 1rem 0 !important;
  padding-left: 0;
  display: block !important;
  list-style: none;
  white-space: pre-line;
}

.visited {
  color: var(--clr-neutral-300);
}

.cursor-pointer {
  cursor: pointer;
}

#closeQuestionsLine {
  border-left: 0 !important;
  margin: 0.2rem !important;
  display: inline-block !important;
  letter-spacing: 0.05rem !important;
  margin-top: 1rem !important;
}
