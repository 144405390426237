:root {
  --clr-primary-000: transparent;
  --clr-primary-100: #ffff;
  --clr-primary-200: #163761be;
  --clr-primary-300: #236f8a;
  --clr-primary-500: #58a7f7;
  --clr-primary-800: rgb(1, 9, 27);
  --clr-primary-900: black;

  --clr-warning: rgb(235, 110, 105);
  --clr-yellow: yellow;
  --clr-green: rgb(125, 215, 120);
  --clr-neutral-100: white;
  --clr-neutral-200: #929191;
  --clr-neutral-300: rgb(180, 180, 180);

  --ff-primary: "Futura", sans-serif;
  --ff-secondary: "Source Sans Pro", Arial, sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* typography */

html {
  font-size: 100%;
}

body {
  font-size: 1rem;
  font-weight: 300;
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

.place {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  letter-spacing: 0.05rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: smaller;
}

h2 {
  font-size: 2rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 0.6rem;
}

h3 {
  font-size: 1rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 0.3rem;
}
h4 {
  font-size: 1rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: var(--clr-yellow);
  letter-spacing: 0.3rem;
}

input {
  text-align: center;
  background-color: white;
  color: var(--clr-primary-800);
  display: inline-block;
  height: 1.5rem;
  font-size: 0.8rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  border: none;
  height: 35px;
  padding: 0.5rem 0;
  border-radius: 16px;
  margin: 0.5rem 0;
}

.warning {
  background-color: var(--clr-warning) !important;
}

.warning:hover {
  background-color: white !important;
}

.caution {
  background-color: var(--clr-yellow) !important;
}

.caution:hover {
  background-color: white !important;
}

#footer {
  font-family: var(--ff-primary);
  font-size: 0.5rem;
  color: var(--clr-neutral-100);
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-align: center;
}
#footer > h3 {
  font-size: 0.6rem;
}

#copyright {
  color: var(--clr-neutral-100);
  font-family: var(--ff-primary);
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  line-height: 1.5;
}

button {
  font-family: var(--ff-primary);
  color: var(--clr-neutral-100);
  font-weight: 900 !important;
  letter-spacing: 0.1rem !important;
  background-color: #63a8c7;
  height: 1.5rem !important;
  font-size: 0.5rem !important;
}

.back-button {
  margin: 1rem 0 0 0;
  cursor: pointer;
}

.warning {
  background-color: var(--clr-warning) !important;
}

.warning:hover {
  background-color: white !important;
}

.text-center {
  text-align: center;
}
.place-description {
  color: yellow;
  font-weight: normal;
  padding: 0.5rem;
}

.carousel-card img {
  margin: 0.5rem !important;
}

.carousel-card {
  max-width: 250px;
  height: 420px;
}
.alice-carousel {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

@media screen and (min-width: 420px) {
  html {
    font-size: 90%;
  }
}

@media screen and (min-width: 1020px) {
  html {
    font-size: 120%;
  }
}
@media screen and (min-width: 1020px) and (max-height: 780px) {
  html {
    font-size: 90%;
  }
}
@media screen and (min-width: 2200px) {
  html {
    font-size: 150%;
  }
}

/* general */

@media screen and (max-width: 480px) {
  html,
  body {
    min-width: 320px;
  }
}

html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

body,
h2,
h3,
p {
  margin: 0;
}

h2,
p {
  margin-bottom: 1rem;
}

img {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

ol,
ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.flex {
  display: flex;
}

.dialogue-line {
  border-left: 0;
  margin: 1rem 0 1rem 0 !important;
  padding-left: 0;
  display: block !important;
  list-style: none;
  white-space: pre-line;
}

.cursor-pointer {
  cursor: pointer;
}

.flex * {
  padding: 0.25rem;
}

.padding-4rem {
  padding: 4rem;
}

.padding-1rem {
  padding: 1rem;
}

.margin-inline-1rem {
  margin-inline: 1rem;
}

.margin-1rem {
  margin: 1rem;
}

.no-padding-top {
  padding-top: 0 !important;
}

#container {
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-inline: auto;
}

a {
  color: inherit;
}

/* layout */

.section-split {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: nowrap;
}

@media screen and (min-width: 1020px) {
  .section-split {
    margin-bottom: 5rem;
  }
}

@media (min-width: 480px) {
  .section-split {
    flex-direction: row;
  }
  .section-split > * {
    flex-basis: 100%;
  }
}

@media screen and (max-width: 640px) {
  .section-split {
    flex-wrap: wrap;
  }
}

.split {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (min-width: 1024px) {
  .split {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
}

.flex-auto {
  flex: auto;
}

.main-wrapper {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  display: block;
  flex-direction: column;
}

.main-wrapper ul li {
  border-left: 0;
  margin: 0.5rem 0 0 0;
  padding-left: 0;
  display: block;
  list-style: none;
}

.place {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  flex: auto;
  display: flex;
  margin-top: 5rem;
}

.place input {
  width: 150px;
  background-color: white !important;
  color: var(--clr-primary-800) !important;
  display: inline-block;
  height: 1.5rem;
  font-size: 0.8rem;
  padding: 0 0.5rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 15px;
  border: none !important;
  margin: 0.5rem 0.5rem 1.5rem 0.5rem;
}

.place p {
  margin-bottom: 1rem;
}

.scroll {
  overflow: scroll;
}

/* button */
.place button {
  color: var(--clr-primary-100);
  border: 1px solid var(--clr-primary-100);
  height: 1.5rem;
  font-size: 0.6rem;
  padding: 0 1rem;
  margin: 0.5rem 0 !important;
}

.place button:hover {
  border: none;
}

@media screen and (min-width: 960px) {
  .place h2 {
    align-self: flex-start;
    justify-self: left;
    margin-right: -100px;
  }
}

.desktop {
  display: none;
}

.win {
  color: greenyellow;
}
.lose {
  color: red;
}

.header-container {
  margin-bottom: 3rem;
}

@media screen and (min-width: 480px) {
  .align-self-flex-start {
    align-self: flex-start;
  }
  .margin-bottom-0 {
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 960px) {
  .border {
    border-bottom: none;
  }
}

.not-found p {
  margin-bottom: 0;
}

.border-radius {
  border-radius: 10px;
}

.padding-places {
  padding: 1rem;
}

.padding-botton-3rem {
  padding-bottom: 3rem;
}

.padding-bottom-05rem {
  padding-bottom: 0.5rem;
}

.padding-1rem {
  padding: 1rem;
}
.margin-top-1-5rem {
  margin-top: 1.5rem !important;
}

.centered-2rem {
  margin: 0 3rem;
}

.padding-3rem {
  padding: 3rem;
}

.margin-1rem {
  margin: 1rem;
}
.place .button-margin {
  margin: 0.5rem auto !important;
}

.margin-block-alchemist-container {
  margin: auto;
}
.margin-0-3rem {
  margin: 0.3rem !important;
}

.margin-bottom-2rem {
  margin-bottom: 2rem !important;
}
.labels-container {
  display: flex;
}

.centered {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.labels {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.article-width {
  margin: auto;
  padding: 1rem;
}

@media screen and (min-width: 480px) {
  .article-width {
    width: 80%;
    padding: 2rem;
  }
}

@media screen and (min-width: 960px) {
  .article-width {
    width: 60%;
    padding: 2rem;
  }
}

/* button */
.button {
  background-color: white !important;
  color: rgb(1, 9, 27) !important;
  cursor: pointer;
  display: inline-block;
  height: 1.5rem;
  font-size: 0.6rem;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  border-radius: 15px;
  border: none !important;
  margin: 0.5rem;
}
.button:hover {
  border: none !important;
  text-decoration: none;
  background-color: yellow !important;
}

.button.small {
  font-size: 0.4rem;
  height: 1.4rem;
  line-height: 1.2rem;
  padding: 0 0.5rem;
}

.button.large {
  font-size: 0.6rem;
  height: 1.8rem;
  line-height: 1.5rem;
  padding: 0 0.8rem;
}

.menu-button {
  color: var(--clr-primary-100);
  border: 1px solid var(--clr-primary-100);
  width: 100%;
  margin: 0.5rem 0 !important;
  height: 35px !important;
  padding: 0.5rem 0;
  border-radius: 16px;
  font-size: 0.6rem !important;
}

.modal-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
  cursor: pointer;
}

.modal-description {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.button:hover {
  border: none;
}
.button a {
  display: block;
}

#confirmationModal .bg-green {
  background-color: rgb(125, 215, 120) !important;
}
#confirmationModal .bg-green:hover {
  background-color: white !important;
}

#confirmationModal .bg-red {
  background-color: rgb(235, 110, 105) !important;
}
#confirmationModal .bg-red:hover {
  background-color: white !important;
}

a {
  display: block;
}
.cubical {
  border-radius: 5px;
}

.no-decoration {
  text-decoration: none;
}

.list-horizontal {
  margin: 0 !important;
  padding-left: 0;
  list-style: none;
}

.list-horizontal li {
  border-left: 0 !important;
  margin: 0.2rem !important;
  display: inline-block !important;
  letter-spacing: 0.05rem !important;
}

.dark-transparent-bg {
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.6);
}

/* modal */
.ReactModalPortal ul,
.ReactModalPortal ul li {
  border-left: 0;
  margin: 1rem 0 0 0;
  padding-left: 0;
  display: block;
  letter-spacing: 0.05rem;
}
.ReactModalPortal ul {
  margin: 3rem;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 100ms;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

/* scroll */

html {
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

/* other */

.multi-line-toast {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
