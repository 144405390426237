@media screen and (min-width: 960px) {
  .content-help {
    width: 80%;
    margin-left: 5rem;
    margin-right: 3rem;
  }
  .underline-places-help-left {
    width: 60%;
    height: 0.1rem;
    margin-right: -2rem;
    background-color: white;
    align-self: center;
    margin-left: 1rem;
  }
  .underline-places-help-right {
    width: 60%;
    height: 0.1rem;
    margin-left: -2rem;
    background-color: white;
    align-self: center;
    margin-right: 1rem;
  }
  .places-header-help {
    display: flex;
    width: min(40%, 70rem);
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
}

.padding-1rem {
  padding: 1rem;
}

.desktop {
  display: none;
}

.place-description-help {
  margin-bottom: 0 !important;
  letter-spacing: 0.06rem;
  margin-right: auto;
  justify-content: center;
  align-content: center;
  align-self: center;
}

@media screen and (min-width: 480px) {
  .place-container-help {
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: flex-start;
    border-radius: 10px;
  }
  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
}

.place-split-help {
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
}

@media screen and (min-width: 1020px) {
  .place-split-help {
    margin-bottom: 5rem;
  }
}

@media (min-width: 480px) {
  .place-split-help {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .place-split-help > * {
    flex-basis: 100%;
  }
}

@media screen and (min-width: 480px) {
  .underline-places-help-left,
  .underline-places-help-right {
    width: 70%;
  }
}

@media screen and (min-width: 960px) {
  .underline-places-help-left {
    width: 60%;
    height: 0.1rem;
    margin-right: -2rem;
    background-color: white;
    align-self: center;
    margin-left: 1rem;
  }
  .underline-places-help-right {
    width: 60%;
    height: 0.1rem;
    margin-left: -2rem;
    background-color: white;
    align-self: center;
    margin-right: 1rem;
  }
  .places-header-help {
    display: flex;
    width: min(40%, 70rem);
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  .place-container-help h3 {
    justify-self: center;
  }
}

@media screen and (max-width: 960px) {
  .place-description-help {
    border-left: none;
  }
}

.place-description-help {
  display: flex;
  flex-direction: column;
  align-items: center;
}
