/* typography */
.place-container-help h3 {
  color: var(--clr-primary-100);
}
#help {
  font-family: var(--ff-primary);
  color: var(--clr-primary-100);
  letter-spacing: 0.05rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: smaller;
}

#help h2 {
  font-size: 1.5rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 0.6rem;
}

@media screen and (min-width: 960px) {
  #help h2 {
    font-size: 2rem;
  }
}
@media screen and (min-width: 960px) {
  .content-help {
    width: 80%;
    margin-left: 5rem;
    margin-right: 3rem;
  }
}

#help h3 {
  font-size: 1rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: yellow;
  letter-spacing: 0.3rem;
}
#help h4 {
  font-size: 1rem;
  font-weight: 200;
  font-family: var(--ff-primary);
  text-transform: uppercase;
  color: yellow;
  letter-spacing: 0.3rem;
}
.place-info-container-help h4 {
  color: yellow !important;
}

/* layout */
#help {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  min-height: 90vh;
  margin-top: 5rem;
}

#help section {
  margin-bottom: 3rem;
}
.flex-auto {
  flex: auto;
}
@media screen and (min-width: 1024px) {
  .place-activities-container-help {
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 960px) {
  .place-activities-container-help {
    margin-top: 2rem;
    width: min(70%, 70rem);
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .place-info-container-help {
    bottom: 2rem;
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }
  .map-header-help {
    margin-bottom: 5rem;
    width: min(40%, 70rem);
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
  }
}
.flex-centered {
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  justify-content: center;
  justify-items: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.place-content-container-help {
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: min(100%, 70rem);
  padding-bottom: auto;
  text-align: center;
}
.header-container-help {
  margin-bottom: 3rem;
}
.underline-help {
  display: none;
}
.underline-places-help-left {
  width: 60%;
  height: 0.1rem;
  margin-right: -2rem;
  background-color: white;
  align-self: center;
  margin-left: 1rem;
}
.underline-places-help-right {
  width: 60%;
  height: 0.1rem;
  margin-left: -2rem;
  background-color: white;
  align-self: center;
  margin-right: 1rem;
}
.places-header-help {
  display: flex;
  width: min(40%, 70rem);
  margin-inline: auto;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.place-container-help {
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: min(80%, 70rem);
  padding-bottom: auto;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.place-container-help article article {
  text-align: center;
}
.desktop {
  display: none;
}
.padding-1rem {
  padding: 1rem;
}

.place-description-help {
  margin-bottom: 0 !important;
  letter-spacing: 0.06rem;
  margin-right: auto;
  justify-content: center;
  align-content: center;
  align-self: center;
}

@media screen and (min-width: 480px) {
  .place-container-help {
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: flex-start;
    border-radius: 10px;
  }

  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
}

@media screen and (min-width: 960px) {
  .underline-help {
    display: block;
    width: 65%;
    height: 0.1rem;
    margin-left: -2rem;
    background-color: white;
    margin-bottom: 25px;
  }
}

.place-split-help {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 1020px) {
  .place-split-help {
    margin-bottom: 5rem;
  }
}

@media (min-width: 480px) {
  .place-split-help {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .place-split-help > * {
    flex-basis: 100%;
  }
}

@media screen and (min-width: 480px) {
  .underline-help {
    width: 70%;
    margin-bottom: 25px;
  }
  .underline-places-help-left,
  .underline-places-help-right {
    width: 70%;
  }
}

@media screen and (min-width: 960px) {
  #help h2 {
    align-self: flex-start;
    justify-self: left;
    margin-right: -100px;
  }
  .underline-help {
    width: 65%;
    height: 0.1rem;
    margin-left: -2rem;
    background-color: white;
    margin-bottom: 25px;
  }
  .underline-places-help-left {
    width: 60%;
    height: 0.1rem;
    margin-right: -2rem;
    background-color: white;
    align-self: center;
    margin-left: 1rem;
  }
  .underline-places-help-right {
    width: 60%;
    height: 0.1rem;
    margin-left: -2rem;
    background-color: white;
    align-self: center;
    margin-right: 1rem;
  }
  .places-header-help {
    display: flex;
    width: min(40%, 70rem);
    margin-inline: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  .place-container-help h3 {
    justify-self: center;
  }

  .sidebar-help {
    display: flex;
    padding: 1rem !important;
    margin-bottom: 0 !important;
    letter-spacing: 0.06rem;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    justify-content: center;
    align-content: right;
    align-self: center;
  }
}
@media screen and (max-width: 960px) {
  .sidebar-help {
    flex-direction: row;
    border-right: none;
    text-align: center;
  }
}

@media screen and (max-width: 960px) {
  .place-description-help {
    border-left: none;
  }
}

.inline-block {
  display: inline-block;
}

.place-description-help {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 960px) {
  .border {
    border-bottom: none;
  }
}

.not-found p {
  margin-bottom: 0;
}

.border-radius {
  border-radius: 10px;
}

.padding-places {
  padding: 1rem;
  justify-content: flex-start;
}

.padding-botton-3rem {
  padding-bottom: 3rem;
}

.padding-1rem {
  padding: 1rem;
}

.padding-3rem {
  padding: 3rem;
}

.margin-1rem {
  margin: 1rem;
}

#help .button-margin {
  margin: 0.5rem auto !important;
}

.margin-block-place-container {
  margin: auto;
}

#help img {
  width: 55% !important;
}
@media screen and (min-width: 960px) {
  #help img {
    width: 30% !important;
  }
}

@media screen and (min-width: 420px) {
  #help img {
    width: 40% !important;
  }
}
@media screen and (min-width: 1024px) {
  #help img {
    width: 40% !important;
  }
}

@media screen and (min-width: 1280px) {
  #help img {
    width: 25% !important;
  }
}

/* background */
@media screen and (min-width: 1281px) {
  .no-webp .help-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/fireball.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .help-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/fireball.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1280px) {
  .no-webp .help-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/fireball.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .help-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/fireball.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .help-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/fireball.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .help-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/fireball.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}
