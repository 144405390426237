/* layout */
#smuggler {
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  flex: auto;
  display: flex;
  margin-top: 5rem;
}

#smuggler input {
  width: 220px;
  background-color: white !important;
  color: rgb(1, 9, 27) !important;
  display: inline-block;
  height: 1.5rem;
  font-size: 0.8rem;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 15px;
  border: none !important;
  margin: 0.5rem;
}

.smuggler-form {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

#smuggler p {
  margin-bottom: 1rem;
}

#smuggler .button-margin {
  margin: 0.5rem auto !important;
}

.margin-block-smuggler-container {
  margin: auto;
}
.margin-0-3rem {
  margin: 0.3rem !important;
}

.margin-bottom-2rem {
  margin-bottom: 2rem !important;
}
.inline-grid {
  display: inline-grid !important;
  display: -ms-inline-grid !important;
  display: -moz-inline-grid !important;
}

.vertical {
  display: flex;
  flex-direction: column;
}

/* background */

@media screen and (min-width: 1281px) {
  .no-webp .smuggler-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/tunnel.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .smuggler-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1920/tunnel.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}
@media screen and (max-width: 1280px) {
  .no-webp .smuggler-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/tunnel.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .smuggler-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/1280/tunnel.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}

@media screen and (max-width: 640px) {
  .no-webp .smuggler-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/tunnel.jpg);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
  .webp .smuggler-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(/src/images/planet-images/960/tunnel.webp);
    background-size: cover;
    overflow: scroll;
    min-height: 100vh;
    background-repeat: no-repeat;
    margin-top: -5rem;
  }
}
