/* thumbnails */
#mercenaries-list .thumbnail img {
  width: 60% !important;
  border-radius: 15px;
}

@media screen and (max-width: 480px) {
  #mercenaries-list .thumbnail img {
    width: 80% !important;
  }
}

@media screen and (min-width: 480px) {
  #mercenaries-list .thumbnail img {
    max-width: 200px;
  }
}

#mercenaries-list .thumbnail img {
  cursor: pointer;
}
